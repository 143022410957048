var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[(_vm.computedAllFlightsSelected)?_c('v-expansion-panel-header',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.$store.state.PlaneList.filter(function (e) { return e.planeID === this$1.planeID; })[0].callsign)+" ")]):_c('v-expansion-panel-header',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.$store.state.PlaneList.filter(function (e) { return e.planeID === this$1.planeID; })[0].callsign)+" "),_c('v-spacer'),_vm._v("ACHTUNG - Flüge ausgeblendet"),_c('v-spacer')],1),_c('v-expansion-panel-content',{staticClass:"customExpansionPanelContent"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.LogbookHeaders,"items":_vm.computedLogbookEntrys,"no-data-text":"Keine Flüge vorhanden...","hide-default-footer":"","disable-pagination":""}}),_c('v-divider'),_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Flüge aus-/einbeziehen ")]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.planeFlights,"item-key":"flightID","show-select":"","no-data-text":"Keine Flüge vorhanden...","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.callsign",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.planeID !== null ? _vm.$store.state.PlaneList.filter(function (e) { return e.planeID == item.planeID; })[0] .callsign : item.callsign))])]}},{key:"item.pilotname",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.pilotID != null ? _vm.$store.state.PilotList.filter(function (e) { return e.userID == item.pilotID; })[0].firstname + " " + _vm.$store.state.PilotList.filter(function (e) { return e.userID == item.pilotID; })[0].lastname : item.pilotName))])]}},{key:"item.copilotname",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.passengerID != null ? _vm.$store.state.PilotList.filter( function (e) { return e.userID == item.passengerID; } )[0].firstname + " " + _vm.$store.state.PilotList.filter( function (e) { return e.userID == item.passengerID; } )[0].lastname : item.passengerName))])]}},{key:"item.flightType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.flightType != null ? _vm.$store.state.FlightTypeList.filter(function (e) { return e.typeID == item.flightType; })[0].title : "fail"))])]}},{key:"item.launchtype",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.launchMethod != null ? _vm.$store.state.LaunchmethodList.filter( function (e) { return e.launchID == item.launchMethod; })[0].title : "fail"))])]}},{key:"item.toLocation",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.takeoffLocation))])]}},{key:"item.toTime",fn:function(ref){
var item = ref.item;
return [(item.takeoffTime != null)?_c('span',[_vm._v(_vm._s(_vm.TimestampToTime(item.takeoffTime))+" UTC")]):_c('span',[_c('v-btn',{attrs:{"disabled":""}},[_vm._v("TO")])],1)]}},{key:"item.landTime",fn:function(ref){
var item = ref.item;
return [(item.landingTime != null)?_c('span',[_vm._v(_vm._s(_vm.TimestampToTime(item.landingTime))+" UTC")]):_c('span',[_c('v-btn',{attrs:{"disabled":""}},[_vm._v("LAND")])],1)]}},{key:"item.landLocation",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.landingLocation))])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [(item.takeoffTime != null && item.landingTime != null)?_c('span',[_vm._v(_vm._s(_vm.calculateTimeDifference(item.takeoffTime, item.landingTime)))]):(
            item.takeoffTime != null &&
            item.landingTime == null
          )?_c('span',[_vm._v(_vm._s(_vm.calculateTimeDifference(item.takeoffTime, _vm.moment().utc().format('YYYY-MM-DD HH:mm:ss'))))]):_c('span',[_vm._v("-----")])]}},{key:"item.payment",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$store.state.PaymentTypeList.filter(function (e) { return e.paymenttypeID === item.paymentMethod; })[0].title))])]}}]),model:{value:(_vm.selectedFlights),callback:function ($$v) {_vm.selectedFlights=$$v},expression:"selectedFlights"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-data-table
    :headers="headers"
    :items="Planes"
    no-data-text="Keine Flugzeuge vorhanden..."
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Flugzeuge</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Neues Flugzeug
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.callsign"
                      label="Kennzeichen"
                      :rules="rules.callsignRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.planeName"
                      label="Name"
                      :rules="rules.planeNameRules"
                    ></v-text-field>
                  </v-col>
                                    <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete :items="$store.state.PlaneTypeList" label="Typ" v-model="editedItem.type" item-value="typeID" item-text="title"></v-autocomplete>
                  </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.seats"
                      :rules="rules.seatsRules"
                      label="Anzahl Sitze"
                      type="number"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                    :rules="rules.clubRules"
                      v-model="editedItem.club"
                      label="Club"
                      required
                    ></v-text-field>
                  </v-col>
                   <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                       <v-menu
        v-model="editMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :disabled="editedItem.club != 'FCBB'"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="editedItem.arcDueDate"
            :disabled="editedItem.club != 'FCBB'"
            label="ARC bis"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
        :disabled="editedItem.club != 'FCBB'"
          v-model="editedItem.arcDueDate"
          @input="editMenu = false"
        ></v-date-picker>
      </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                        <v-checkbox
      v-model="editedItem.towplane"
      label="Schleppflugzeug"
    ></v-checkbox>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="close"
              >
                Abbrechen
                   </v-btn>
                                 <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="save"
              >
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
             <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:item.type="{ item }">
    <span>{{ $store.state.PlaneTypeList.filter(e => e.typeID == item.type)[0].title }}</span>
  </template>
    <template v-slot:item.arcDueDate="{ item }">
    <v-chip
        :color="getColor(calculateDateDifference(item.arcDueDate))"
        dark
      >
        {{ formatDate(item.arcDueDate) }}
      </v-chip>
  </template>
      <template v-slot:item.towplane="{ item }">
    <v-icon>{{ item.towplane ? 'fa-check' : 'fa-times'}}</v-icon>
  </template>
  </v-data-table>
</template>
<script>
import moment from 'moment'
export default {
  data: () => ({
    dialog: false,
    search: '',
    headers: [
      {
        text: 'Callsign',
        align: 'start',
        value: 'callsign'
      },
      { text: 'Flugzeug', value: 'planeName' },
      { text: 'Typ', value: 'type' },
      { text: 'Sitze', value: 'seats' },
      { text: 'Club', value: 'club' },
      { text: 'ARC bis', value: 'arcDueDate' },
      { text: 'Schleppflugzeug', value: 'towplane' },
      { text: 'Aktionen', value: 'actions', sortable: false }

    ],
    editMenu: false,
    editedIndex: -1,
    editedItem: {
      arcDueDate: '',
      callsign: '',
      club: '',
      planeName: '',
      seats: '',
      towplane: '',
      type: ''
    },
    defaultItem: {
      arcDueDate: '',
      callsign: '',
      club: '',
      planeName: '',
      seats: '',
      towplane: '',
      type: ''
    },
    rules: {
      callsignRules: [
        value => !!value || 'Callsign wird benötigt!',
        value => /[A-Za-z]{1,2}-[0-9]{4}|[A-Za-z]{1,2}-[A-Za-z]{4}/.test(value) || 'Callsign entspricht nicht den Richtlinien'
      ],
      planeNameRules: [
        value => !!value || 'Name wird benötigt!',
        value => value.length < 32 || 'Maximal 32 Zeichen erlaubt'
      ],
      clubRules: [
        value => !!value || 'Club wird benötigt',
        value => value.length < 32 || 'Maximal 32 Zeichen erlaubt'
      ],
      seatsRules: [
        value => !!value || 'Anzahl der Sitze wird benötigt!'
      ]
    }
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Neues Flugzeug' : 'Flugzeug bearbeiten'
    },
    Planes () {
      return this.$store.state.PlaneList
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  methods: {
    formatDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    calculateDateDifference: function (referenceDate) {
      var today = moment()
      var reference = moment(referenceDate)
      //  console.log(reference.diff(today, 'days'))
      return reference.diff(today, 'days')
    },
    getColor: function (days) {
      if (days <= 14) {
        return 'red'
      } else if (days <= 28) {
        return 'orange'
      } else {
        return ''
      }
    },
    editItem (item) {
      this.editedIndex = this.$store.state.PlaneList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        // validate Plane
        this.editedItem.towplane = this.editedItem.towplane ? 1 : 0
        this.$store.dispatch('validatePlane', this.editedItem).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            // Update Plane
            this.$store.dispatch('updatePlane', this.editedItem).then(response => {
              //  console.log(response)
              if (parseInt(response.data.noErrors) === 0) {
                // Update Plane
                this.$store.dispatch('loadPlaneList')
                this.close()
              } else {
                this.$store.commit('setApiErrorResponse', response.data)
              }
            })
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        // validate Plane
        this.editedItem.towplane = this.editedItem.towplane ? 1 : 0
        this.$store.dispatch('validatePlane', this.editedItem).then(response => {
          //  console.log(response.data.noErrors)
          if (response.data.noErrors === 0) {
            //  console.log('PLANE VALID.....INSERTING')
            // Insert Plane
            this.$store.dispatch('insertPlane', this.editedItem).then(response => {
              //  console.log(response)
              if (response.data.noErrors === 0) {
                // Update Plane
                this.$store.dispatch('loadPlaneList')
                this.close()
              } else {
                this.$store.commit('setApiErrorResponse', response.data)
              }
            })
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      }
    }
  }
}
</script>

<template>
<v-container fluid>
  <div v-if="$route.params.code === undefined">
    <v-col lg="2">
      <v-text-field
            v-model="couponCode"
            label="Gutschein CODE"
            outlined
            clearable
          ></v-text-field>
    </v-col>
    <v-col lg="2">
      <v-btn
      rounded
      color="primary"
      dark
      @click="$router.push({ name: 'couponCode', params: { code: couponCode } })"
    >
      Gutschein auswählen/prüfen
    </v-btn>
    </v-col>
  </div>
  <div v-else>
    <h2>{{computedTitle}}</h2>
    <v-col lg="3">
      <v-btn
      style="width: 100%"
      rounded
      color="grey"
      dark
      @click="$router.push({ name: 'coupon' })"
    >
      zurück
    </v-btn>
    </v-col>
    <v-col lg="3">
      <v-text-field type="number" steps="0.1" dense label="WERT" v-if="couponInfo != null" v-model="couponInfo.value" outlined :disabled="!computedEditMode"></v-text-field>
    </v-col>
    <v-col lg="3">
      <v-text-field type="date"  label="Gültig bis" dense v-if="couponInfo != null" v-model="couponInfo.validUntil" outlined :disabled="!computedEditMode"></v-text-field>
    </v-col>
    <v-col lg="3">
      <v-autocomplete
            v-model="couponInfo.status"
            :items="CouponStatus"
            v-if="couponInfo != null"
            outlined
            dense
            label="Status"
            item-text="title"
            item-value="statusID"
            :disabled="!computedEditMode"
          ></v-autocomplete>
    </v-col>
    <v-col lg="3">
      <v-autocomplete
            v-model="couponInfo.paymentMethod"
            :items="$store.state.CouponPaymentMethodCoupons"
            v-if="couponInfo != null"
            outlined
            dense
            label="Zahlart"
            item-text="title"
            item-value="pID"
            :disabled="!computedEditMode"
          ></v-autocomplete>
    </v-col>
    <v-col lg="3">
      <v-textarea
          outlined
          rows="3"
          v-if="couponInfo != null"
          v-model="couponInfo.comment"
          label="Kommentar"
          value=""
        ></v-textarea>
    </v-col>
    <v-col lg="3">
      <v-btn
      style="width: 100%"
      rounded
      :color="computedBtnColor"
      dark
      @click="update"
    >
      {{ computedBtnText }}
    </v-btn>
    </v-col>
  </div>
</v-container>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
export default {
  name: 'Coupon',
  data: () => ({
    couponCode: '',
    CouponStatus: [
      { title: 'Erstellt und nicht ausgegeben', statusID: 1 },
      { title: 'Ausgegeben und nicht abgeflogen', statusID: 2 },
      { title: 'Abgeflogen', statusID: 3 }
    ],
    couponInfoOld: null,
    couponInfo: null
  }),
  computed: {
    computedBtnColor () {
      if (this.computedEditMode) {
        return 'success'
      }
      return 'red'
    },
    computedBtnText () {
      if (this.computedEditMode) {
        return 'Gutschein aktivieren'
      }
      return 'Manuell als Abgeflogen markieren'
    },
    computedTitle () {
      if (this.computedEditMode) {
        return 'Gutschein aktivieren: ' + this.$route.params.code
      }
      return 'Gutschein abfliegen: ' + this.$route.params.code
    },
    computedEditMode () {
      if (this.couponInfoOld !== null && this.couponInfoOld.status !== 1) {
        return false
      }
      return true
    }
  },
  methods: {
    update () {
      if (confirm('Gutschein ' + this.$route.params.code + (this.computedEditMode ? ' aktivieren?' : ' als abgeflogen markieren?')) === true) {
        if (!this.computedEditMode && this.couponInfo.status === 2) { // Wenn Gutschein ausgegeben und nicht abgeflogen && nicht im EditModus
          this.couponInfo.status = 3 // setze auf abgeflogen
        }
        axios.post(process.env.VUE_APP_API_PATH_ADMIN_COUPON_EDIT, qs.stringify(this.couponInfo), { headers: { Authorization: `Basic ${this.$store.state.User.auth.authStr}` } }).then(response => {
          if (response.data.noErrors === 0) {
            if (this.computedEditMode) {
              alert('Gutschein wurde erfolgreich AKTIVIERT!')
            } else {
              alert('Gutschein wurde als ABGEFLOGEN markiert!')
            }
            this.couponInfo = null
            this.couponInfoOld = null
            this.navigateToCouponInfoPage()
          } else {
            alert('Fehler aufgetreten!')
          }
        })
      }
    },
    validateCode () {
      if (this.$route.params.code !== undefined) {
        console.log(this.$route.params.code)
        // load coupon info
        axios.get(process.env.VUE_APP_API_PATH_ADMIN_COUPON_INFO + '?couponID=' + this.$route.params.code, { headers: { Authorization: `Basic ${this.$store.state.User.auth.authStr}` } }).then(response => {
          console.log(response)
          if (response.data.result.length === 0) {
            alert('Dieser Gutschein-CODE ist NICHT gültig!')
            this.$router.push({ name: 'coupon' })
            return false
          }
          if (response.data.result.length > 1) {
            alert('Ungültige Antwort vom System! Administrator kontaktieren!')
            this.$router.push({ name: 'coupon' })
            return false
          }
          this.couponInfo = response.data.result[0]
          this.couponInfoOld = Object.assign({}, response.data.result[0]) // Copy Object
          if (this.couponInfo.value === null || this.couponInfo.status === 1) { // wenn gutschein kein Wert oder Status = Erstellt
            this.couponInfo.validUntil = moment().add('2', 'years').format('YYYY-MM-DD') // Ablauf default Heute + 2 Jahre
            this.couponInfo.status = 2 // neuer status => Ausgegben aber nicht abgeflogen
            this.couponInfo.paymentMethod = 1 // default "bar"
          } else if (this.couponInfo.status === 2) { // Wenn gutschein ausgegeben und wartet auf abgliegen

          } else {
            this.navigateToCouponInfoPage()
          }
        })
      }
    },
    navigateToCouponInfoPage () {
      if (this.$route.params.code !== undefined) {
        window.location.href = 'https://fcbb.de/Gutschein.php?code=' + this.$route.params.code
      }
    }
  },
  updated () {
    if (this.couponInfo === null) {
      this.validateCode()
    }
  },
  mounted () {
    if (this.$route.query.preselectCode !== undefined) {
      this.couponCode = this.$route.query.preselectCode
    }
    this.validateCode()
  }
}
</script>

<template>
<v-container fluid>
  <PlaneTable/>
</v-container>
</template>

<script>
import PlaneTable from '../components/PlaneList.vue'
export default {
  name: 'Planes',
  components: {
    PlaneTable
  }
}
</script>

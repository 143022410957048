<template>
  <v-row justify="center" v-if="$store.state.User.userID == -1">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Login
        </v-card-title>
        <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="message != ''">
                <v-alert      border="top"
      color="red"
      dark>{{message}}</v-alert>
              </v-col>
              <v-col cols="12">
              <v-text-field
              v-model="auth.username"
              dense
              v-on:keyup.enter="loginUser"
              label="Username">
              </v-text-field>
              </v-col>
<v-col cols="12">
              <v-text-field
              v-model="auth.password"
              v-on:keyup.enter="loginUser"
              dense
              type="password"
              label="Passwort">
              </v-text-field>
</v-col>
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="loginUser"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from 'axios'
var qs = require('qs')
export default {
  data () {
    return {
      auth: {
        username: '',
        password: ''
      },
      message: ''
    }
  },
  methods: {
    loginUser: function () {
      // Validate user Input here
      this.validateUserCredentials().then(response => {
        //  console.log(response)
        if (response.data !== 'NOT AUTHORIZED') {
          if (parseInt(response.data.result) < 0) {
            //  console.log('Login Error')
            this.message = response.data.errorMsg
          } else {
            //  console.log('Login Correct')
            // Store Correct User data
            this.$store.dispatch('loginUser', { userID: parseInt(response.data.result.userID), auth: { username: this.auth.username, password: response.data.result.token, authStr: response.data.result.authStr } })
            // Reset Form
            this.message = ''
            this.auth.username = ''
            this.auth.password = ''
          }
        } else {
          this.message = 'NOT AUTHORIZED'
        }
      })
    },
    async validateUserCredentials () {
      //  console.log(this.auth)
      return axios.post(process.env.VUE_APP_API_PATH_AUTH_USER, qs.stringify({
        userName: this.auth.username,
        password: this.auth.password
      }))
    }
  },
  computed: {
    dialog () {
      if (this.$store.state.User.userID === -1) {
        return true
      }
      return false
    }
  }
}
</script>

<template>
  <div>
    <v-data-table
      :headers="computedHeaders"
      :items="computedFlightList"
      class="elevation-1"
      id="flighttable"
      multi-sort
      mobile-breakpoint="0"
      hide-default-footer
      disable-pagination
      no-data-text="Keine Flüge vorhanden..."
      :item-class="itemRowBackground"
      @contextmenu:row="rightClickHandler"
      @dblclick:row="rightClickHandler"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="hidden-sm-and-down">Heute</v-toolbar-title>
          <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
          <v-dialog v-model="dialog" persistent max-width="1000px"  :fullscreen="$vuetify.breakpoint.name == 'xs'">
            <template v-slot:activator="{ on }">
              <v-btn color="success" dark @click="openDialog" v-on="on">
                <v-icon left>
                  fa-plus
                </v-icon>
                Neuer Flug
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span
                ><v-spacer></v-spacer>
                <v-text-field type="date" label="Flugdatum" color="blue" v-model="$store.state.FlightLogDate"></v-text-field>
                <v-spacer></v-spacer
                ><v-btn @click="resetForm"  v-if="editedIndex === -1" text outlined color="primary">Formular leeren</v-btn>
                <v-spacer v-if="editedIndex != -1"></v-spacer
                >{{
                  editedIndex != -1
                    ? "#" + editedItem.flightID
                    : ""
                }}
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-row>
                        <v-col cols="12" sm="12" md="4">
                          <v-autocomplete
                            v-model="editedItem.planeID"
                            :items="$store.state.PlaneList"
                            v-on:change="planeSelectEvent"
                            item-text="callsign"
                            clearable
                            outlined
                            dense
                            item-value="planeID"
                            label="Flugzeug"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12"  style="transform: translateY(-40px); margin-bottom: -45px;" class="hidden-md-and-up" v-if="editedItem.planeID == null && computedMostRecentPlanes.length > 0">
                          <v-btn
                          v-for="plane in computedMostRecentPlanes"
                          :key="plane.planeID"
                            rounded
                            @click="editedItem.planeID = plane.planeID; planeSelectEvent()"
                            color="grey"
                            dark
                          >
                            {{ plane.callsign }}
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                          <v-text-field
                            :disabled="editedItem.planeID != null"
                            v-model="editedItem.callsign"
                            label="Alt. Kennz."
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                          <v-text-field
                            :disabled="editedItem.planeID != null"
                            v-model="editedItem.planeClub"
                            label="Alt. Club"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="editedItem.flightType"
                            :items="computedFlightTypeList"
                            item-text="title"
                            item-value="typeID"
                            dense
                            outlined
                            clearable
                            label="Flugtyp"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" style="transform: translateY(-40px); margin-bottom: -45px;" v-if="editedItem.planeID != null && editedItem.flightType == null && computedMostRecentFlightTypes.length > 0 ">
                          <v-btn
                          v-for="flightType in computedMostRecentFlightTypes"
                          :key="flightType.typeId"
                            rounded
                            small
                            style="margin-top: 5px;"
                            color="grey"
                            dark
                            @click="editedItem.flightType = flightType.typeID"
                          >
                            {{ flightType.title }}
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            v-model="editedItem.pilotID"
                            :items="computedPilotList"
                            @input="editedItem.pilotName = ''"
                            clearable
                            dense
                            outlined
                            item-value="userID"
                            item-text="fullname"
                            label="Pilot"
                            :no-data-text="computedNoPilotListText"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12"  class="hidden-md-and-up" style="transform: translateY(-40px); margin-bottom: -45px;" v-if="editedItem.pilotID == null && computedPilotList.length > 0 ">
                          <v-btn
                            rounded
                            small
                            style="margin-top: 5px; width: 100%;"
                            color="grey"
                            dark
                            @click="editedItem.pilotID = $store.state.User.userID"
                          >
                            {{ computedPilotListAll.filter(e => e.userID == $store.state.User.userID)[0].firstname + ' ' + computedPilotListAll.filter(e => e.userID == $store.state.User.userID)[0].lastname}}
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            :disabled="editedItem.pilotID != null"
                            v-model="editedItem.pilotName"
                            label="Alt. Pilot"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" class="hidden-sm-and-down" style="transform: translateY(-40px); margin-bottom: -45px;" v-if="editedItem.pilotID == null && computedPilotList.length > 0 ">
                          <v-btn
                            rounded
                            small
                            style="margin-top: 5px; width: 100%;"
                            color="grey"
                            dark
                            @click="editedItem.pilotID = $store.state.User.userID"
                          >
                            {{ computedPilotListAll.filter(e => e.userID == $store.state.User.userID)[0].firstname + ' ' + computedPilotListAll.filter(e => e.userID == $store.state.User.userID)[0].lastname}}
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            v-model="editedItem.passengerID"
                            :items="computedCopilotList"
                            :disabled="maxPilotsReached"
                            item-value="userID"
                            item-text="fullname"
                            label="Begleiter"
                            @input="editedItem.passengerName = ''"
                            dense
                            outlined
                            clearable
                            :no-data-text="computedNoPilotListText"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12"  class="hidden-md-and-up" style="transform: translateY(-40px); margin-bottom: -45px;" v-if="editedItem.planeID != null && computedPilotList.length > 0 && editedItem.passengerID == null && !maxPilotsReached ">
                          <v-btn
                          v-for="i in computedPassengerRecomendation"
                          :key="i+'_passenger'"
                            rounded
                            small
                            @click="editedItem.passengerID = parseInt(i)"
                            color="grey"
                            dark
                          >
                            {{ computedPilotListAll.filter(e => e.userID == i)[0].firstname + ' ' + computedPilotListAll.filter(e => e.userID == i)[0].lastname }}
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            :disabled="
                              editedItem.passengerID != null || maxPilotsReached
                            "
                            v-model="editedItem.passengerName"
                            label="Alt. Begleiter"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12"  class="hidden-sm-and-down" style="transform: translateY(-40px); margin-bottom: -45px;" v-if="editedItem.planeID != null && computedPilotList.length > 0 && editedItem.passengerID == null && !maxPilotsReached ">
                          <v-btn
                          v-for="i in computedPassengerRecomendation"
                          :key="i+'_passenger'"
                            rounded
                            small
                            @click="editedItem.passengerID = parseInt(i)"
                            color="grey"
                            dark
                          >
                            {{ computedPilotListAll.filter(e => e.userID == i)[0].firstname + ' ' + computedPilotListAll.filter(e => e.userID == i)[0].lastname }}
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="editedItem.paymentMethod"
                            :items="$store.state.PaymentTypeList"
                            item-text="title"
                            item-value="paymenttypeID"
                            label="Zahlart"
                            dense
                            outlined
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12"  class="" style="transform: translateY(-40px); margin-bottom: -45px;" v-if="editedItem.flightType != null && computedPaymentTypeRecommendation.length > 0">
                          <v-btn
                          v-for="i in computedPaymentTypeRecommendation"
                          :key="i+'_paymentType'"
                            rounded
                            small
                            @click="editedItem.paymentMethod = parseInt(i)"
                            color="grey"
                            dark
                          >
                            {{ $store.state.PaymentTypeList.filter(e => e.paymenttypeID == i)[0].title  }}
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12"  class="" style="transform: translateY(-40px); margin-bottom: -45px;" v-if="(editedItem.payerID == null || editedItem.payerID.length < 2) && editedItem.paymentMethod == 5 && editedItem.pilotID != null && editedItem.passengerID != null">
                          <v-btn
                            rounded
                            small
                            @click="setPilotAndPassengerAsPaySplit"
                            color="grey"
                            dark
                          >
                           50/50
                          </v-btn>
                          <v-btn
                            rounded
                            small
                            @click="editedItem.payerID = editedItem.passengerID"
                            color="grey"
                            dark
                          >
                           Begleiter
                          </v-btn>
                          <v-btn
                            rounded
                            small
                            @click="editedItem.payerID = null; editedItem.paymentMethod = 1"
                            color="grey"
                            dark
                          >
                           Pilot
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-if="editedItem.paymentMethod === 5"
                        >
                          <v-autocomplete
                            v-model="editedItem.payerID"
                            :items="computedPilotListAll"
                            outlined
                            dense
                            chips
                            small-chips
                            multiple
                            v-on:change="updateDots()"
                            item-value="userID"
                            item-text="fullname"
                            label="Zahler"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-if="editedItem.paymentMethod === 5"
                        >
                          <vue-slider
                            v-model="editedItem.payerSliderSplit"
                            :process="computedPaysplitSliderProcess"
                            v-if="
                              editedItem.paymentMethod === 5 &&
                                editedItem.payerID != null &&
                                editedItem.payerID.length > 1
                            "
                            v-on:drag-end="sliderChange()"
                            v-on:change="sliderChange()"
                            v-on:drag-start="sliderChange()"
                            v-on:dragging="sliderChange()"
                          >
                            <template
                              v-slot:process="{ start, end, style, index }"
                            >
                              <div class="vue-slider-process" :style="style">
                                <div
                                  :class="[
                                    'merge-tooltip',
                                    'vue-slider-dot-tooltip-inner',
                                    'vue-slider-dot-tooltip-inner-top'
                                  ]"
                                >
                                  {{
                                    editedItem.payerSliderSplit[index + 1] -
                                      editedItem.payerSliderSplit[index + 0]
                                  }}%
                                  {{
                                    $store.state.PilotList.filter(
                                      e =>
                                        e.userID === editedItem.payerID[index]
                                    )[0].firstname
                                  }}
                                </div>
                              </div>
                            </template>
                          </vue-slider>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                          v-if="
                            editedItem.paymentMethod === 2 ||
                              editedItem.paymentMethod === 3 ||
                              editedItem.paymentMethod === 4
                          "
                        >
                          <v-text-field
                            v-model="editedItem.payerID"
                            label="Gutschein-Code"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="editedItem.forceActCost"
                            label="Flugkosten überschreib."
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-divider vertical class="hidden-sm-and-down"></v-divider>
                    <v-col cols="12" sm="6">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-autocomplete
                            v-model="editedItem.launchMethod"
                            :items="$store.state.LaunchmethodList"
                            item-text="title"
                            item-value="launchID"
                            label="Startart"
                            dense
                            outlined
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="7">
                          <v-autocomplete
                            v-model="editedItem.takeoffLocation"
                            :items="computedAirportList"
                            :filter="filterAirportObject"
                            v-on:change="changeTakeoffLocation"
                            clearable
                            dense
                            outlined
                            item-value="icao"
                            label="Startort"
                          >
                            <template slot="selection" slot-scope="data">
                              <!-- HTML that describe how select should render selected items -->
                              {{ data.item.icao }} {{ data.item.city }}
                            </template>
                            <template slot="item" slot-scope="data">
                              <!-- HTML that describe how select should render items when the select is open -->
                              {{ data.item.icao }} {{ data.item.city }}
                            </template>
                          </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" style="transform: translateY(-40px); margin-bottom: -45px;" class="hidden-md-and-up">
                          <v-btn
                            v-if = "editedItem.takeoffLocation == null"
                            rounded
                            color="grey"
                            dark
                            @click="editedItem.takeoffLocation = $store.state.presets.defaultLocation"
                          >
                          {{ $store.state.presets.defaultLocation }}
                          </v-btn>
                        </v-col>
                        <v-col cols="1" sm="1" md="1">
                          <v-checkbox
                            v-model="editedItem.manualToTime"
                            :disabled="editedItem.flightType === 9"
                            v-on:change="manualToTimeChangeEvent"
                            color="red"
                            hide-details
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col cols="11" sm="11" md="2">
                          <v-text-field type="time"  :disabled="
                              !editedItem.manualToTime ||
                                editedItem.flightType === 9
                            "
                             v-on:change="changeTakeoffTimeSelector"
                            v-model="editedItem.takeoffTimeSelector">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="7">
                          <v-autocomplete
                            v-model="editedItem.landingLocation"
                            :items="computedAirportList"
                            :filter="filterAirportObject"
                            clearable
                            dense
                            outlined
                            item-value="icao"
                            label="Landeort"
                          >
                            <template slot="selection" slot-scope="data">
                              <!-- HTML that describe how select should render selected items -->
                              {{ data.item.icao }} {{ data.item.city }}
                            </template>
                            <template slot="item" slot-scope="data">
                              <!-- HTML that describe how select should render items when the select is open -->
                              {{ data.item.icao }} {{ data.item.city }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                          <v-col cols="12" sm="12" style="transform: translateY(-40px); margin-bottom: -45px;" class="hidden-md-and-up">
                          <v-btn
                            v-if = "editedItem.landingLocation == null"
                            rounded
                            color="grey"
                            dark
                            @click="editedItem.landingLocation = $store.state.presets.defaultLocation"
                          >
                          {{ $store.state.presets.defaultLocation }}
                          </v-btn>
                        </v-col>
                        <v-col cols="1" sm="1" md="1">
                          <v-checkbox
                            v-model="editedItem.manualLandTime"
                            :disabled="editedItem.flightType === 10"
                            color="red"
                            hide-details
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col cols="11" sm="11" md="2">
                          <v-text-field type="time"  :disabled="
                              !editedItem.manualLandTime ||
                                editedItem.flightType === 10
                            "
                            v-model="editedItem.landingTimeSelector">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6"  v-if="computedPlaneHasEngine">
                          <v-text-field
                            v-model="editedItem.engineStart"
                            type="number"
                            step="0.01"
                            label="Motorzähler Start"
                          ></v-text-field>
                          <a @click="refreshEngineStart">Zähler aktualisieren</a>
                        </v-col>
                        <v-col cols="12" sm="12" md="6"  v-if="computedPlaneHasEngine">
                          <v-text-field
                            v-model="editedItem.engineStop"
                            type="number"
                            step="0.01"
                            label="Motorzähler Stop"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6"  v-if="computedPlaneHasEngine">
                          <v-text-field
                            v-model="editedItem.refuel"
                            type="text"
                            label="Nachgetankt?"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6"  v-if="computedPlaneHasEngine">
                          <v-text-field
                            v-model="editedItem.reoil"
                            type="text"
                            label="Öl nachgefüllt?"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="editedItem.landingsTakeoffLocation"
                            :disabled="(editedItem.takeoffLocation === editedItem.landingLocation && editedIndex === -1) || editedItem.takeoffLocation === null"
                            type="number"
                            label="Landungen am Startort"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="editedItem.landingsLandingLocation"
                            :disabled="
                             editedItem.landingLocation === null
                            "
                            type="number"
                            label="Landungen am Landeort"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="FlightComment"
                            label="Kommentare"
                            rows="3"
                            v-model="editedItem.comment"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col v-if="
                            editedItem.launchMethod === 2
                          ">
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          v-if="
                            editedItem.launchMethod === 2 && editedIndex === -1
                          "
                        >
                          <v-card>
                            <v-row>
                              <v-col cols="6" sm="6">
                                <v-row>
                                  <v-col cols="4" sm="4">
                                    <v-autocomplete
                                      v-model="editedItem.towflight.planeID"
                                      :items="computedTowplaneList"
                                      v-on:change="towPlaneSelectEvent"
                                      item-text="callsign"
                                      clearable
                                      outlined
                                      dense
                                      item-value="planeID"
                                      label="Flugzeug"
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="4" sm="4">
                                    <v-text-field
                                      :disabled="
                                        editedItem.towflight.planeID != null
                                      "
                                      v-model="editedItem.towflight.callsign"
                                      label="Alternative Callsign"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="4" sm="4">
                                    <v-text-field
                                      :disabled="
                                        editedItem.towflight.planeID != null
                                      "
                                      v-model="editedItem.towflight.planeClub"
                                      label="Alternative Club"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12">
                                    <v-autocomplete
                                      v-model="editedItem.towflight.flightType"
                                      :items="computedFlightTypeList"
                                      item-text="title"
                                      item-value="typeID"
                                      dense
                                      outlined
                                      label="Flugtyp"
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="6" sm="6">
                                    <v-autocomplete
                                      v-model="editedItem.towflight.pilotID"
                                      :items="computedTowpilotList"
                                      :filter="filterPilotObject"
                                      clearable
                                      dense
                                      outlined
                                      item-value="userID"
                                      label="Pilot"
                                    >
                                      <template
                                        slot="selection"
                                        slot-scope="data"
                                      >
                                        <!-- HTML that describe how select should render selected items -->
                                        {{ data.item.firstname }}
                                        {{ data.item.lastname }}
                                      </template>
                                      <template slot="item" slot-scope="data">
                                        <!-- HTML that describe how select should render items when the select is open -->
                                        {{ data.item.firstname }}
                                        {{ data.item.lastname }}
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="6" sm="6">
                                    <v-text-field
                                      :disabled="
                                        editedItem.towflight.pilotID != null
                                      "
                                      v-model="editedItem.towflight.pilotName"
                                      label="Alternative Pilot Name"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="6" sm="6">
                                    <v-autocomplete
                                      v-model="editedItem.towflight.passengerID"
                                      :items="computedTowcopilotList"
                                      :disabled="maxTowPilotsReached"
                                      :filter="filterPilotObject"
                                      item-value="userID"
                                      label="Begleiter"
                                      dense
                                      outlined
                                      clearable
                                    >
                                      <template
                                        slot="selection"
                                        slot-scope="data"
                                      >
                                        <!-- HTML that describe how select should render selected items -->
                                        {{ data.item.firstname }}
                                        {{ data.item.lastname }}
                                      </template>
                                      <template slot="item" slot-scope="data">
                                        <!-- HTML that describe how select should render items when the select is open -->
                                        {{ data.item.firstname }}
                                        {{ data.item.lastname }}
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="6" sm="6">
                                    <v-text-field
                                      :disabled="
                                        editedItem.towflight.passengerID !=
                                          null || maxPilotsReached
                                      "
                                      v-model="
                                        editedItem.towflight.passengerName
                                      "
                                      label="Alternative Begleiter Name"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12">
                                    <v-autocomplete
                                      v-model="
                                        editedItem.towflight.paymentMethod
                                      "
                                      :items="$store.state.PaymentTypeList"
                                      item-text="title"
                                      item-value="paymenttypeID"
                                      label="Zahlart"
                                      dense
                                      outlined
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    v-if="
                                      editedItem.towflight.paymentMethod === 5
                                    "
                                  >
                                    <v-autocomplete
                                      v-model="editedItem.towflight.payerID"
                                      :items="$store.state.PilotList"
                                      outlined
                                      dense
                                      chips
                                      small-chips
                                      multiple
                                      v-on:change="updateTowDots()"
                                      item-value="userID"
                                      label="Payer"
                                    >
                                      <template
                                        slot="selection"
                                        slot-scope="data"
                                      >
                                        <!-- HTML that describe how select should render selected items -->
                                        {{ data.item.firstname }}
                                        {{ data.item.lastname }}
                                      </template>
                                      <template slot="item" slot-scope="data">
                                        <!-- HTML that describe how select should render items when the select is open -->
                                        {{ data.item.firstname }}
                                        {{ data.item.lastname }}
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    v-if="
                                      editedItem.towflight.paymentMethod === 5
                                    "
                                  >
                                    <vue-slider
                                      v-model="
                                        editedItem.towflight.payerSliderSplit
                                      "
                                      :process="
                                        computedTowPaysplitSliderProcess
                                      "
                                      v-if="
                                        editedItem.towflight.paymentMethod ===
                                          5 &&
                                          editedItem.towflight.payerID !=
                                            null &&
                                          editedItem.towflight.payerID.length >
                                            1
                                      "
                                      v-on:drag-end="towSliderChange()"
                                      v-on:change="towSliderChange()"
                                      v-on:drag-start="towSliderChange()"
                                      v-on:dragging="towSliderChange()"
                                    >
                                      <template
                                        v-slot:process="{
                                          start,
                                          end,
                                          style,
                                          index
                                        }"
                                      >
                                        <div
                                          class="vue-slider-process"
                                          :style="style"
                                        >
                                          <div
                                            :class="[
                                              'merge-tooltip',
                                              'vue-slider-dot-tooltip-inner',
                                              'vue-slider-dot-tooltip-inner-top'
                                            ]"
                                          >
                                            {{
                                              editedItem.towflight
                                                .payerSliderSplit[index + 1] -
                                                editedItem.towflight
                                                  .payerSliderSplit[index + 0]
                                            }}%
                                            {{
                                              $store.state.PilotList.filter(
                                                e =>
                                                  e.userID ===
                                                  editedItem.towflight.payerID[
                                                    index
                                                  ]
                                              )[0].firstname
                                            }}
                                          </div>
                                        </div>
                                      </template>
                                    </vue-slider>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    v-if="
                                      editedItem.towflight.paymentMethod ===
                                        3 ||
                                        editedItem.towflight.paymentMethod === 4
                                    "
                                  >
                                    <v-text-field
                                      v-model="editedItem.towflight.payerID"
                                      label="Gutschein-Code"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                      v-model="editedItem.towflight.forceActCost"
                                      label="Flugkosten überschreib."
                                      dense
                                      outlined
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-divider vertical></v-divider>
                              <v-col cols="6" sm="6">
                                <v-row>
                                  <v-col cols="12" sm="12">
                                    <v-autocomplete
                                      v-model="
                                        editedItem.towflight.launchMethod
                                      "
                                      :items="$store.state.LaunchmethodList"
                                      disabled
                                      item-text="title"
                                      item-value="launchID"
                                      label="Startart"
                                      dense
                                      outlined
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="6" sm="6">
                                    <v-autocomplete
                                      v-model="
                                        editedItem.towflight.takeoffLocation
                                      "
                                      :items="computedAirportList"
                                      :filter="filterAirportObject"
                                      clearable
                                      dense
                                      outlined
                                      item-value="icao"
                                      label="Startort"
                                    >
                                      <template
                                        slot="selection"
                                        slot-scope="data"
                                      >
                                        <!-- HTML that describe how select should render selected items -->
                                        {{ data.item.icao }}
                                        {{ data.item.city }}
                                      </template>
                                      <template slot="item" slot-scope="data">
                                        <!-- HTML that describe how select should render items when the select is open -->
                                        {{ data.item.icao }}
                                        {{ data.item.city }}
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="1" sm="1">
                                    <v-checkbox
                                      v-model="
                                        editedItem.towflight.manualToTime
                                      "
                                      :disabled="editedItem.flightType === 9"
                                      color="red"
                                      hide-details
                                    >
                                    </v-checkbox>
                                  </v-col>
                                  <v-col cols="11" sm="5">
                                    <v-text-field type="time"  :disabled="
                                        !editedItem.towflight.manualToTime ||
                                          editedItem.flightType === 9
                                      "
                            v-model="editedItem.towflight.takeoffTimeSelector">
                          </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-autocomplete
                                      v-model="
                                        editedItem.towflight.landingLocation
                                      "
                                      :items="computedAirportList"
                                      :filter="filterAirportObject"
                                      clearable
                                      dense
                                      outlined
                                      item-value="icao"
                                      label="Landeort"
                                    >
                                      <template
                                        slot="selection"
                                        slot-scope="data"
                                      >
                                        <!-- HTML that describe how select should render selected items -->
                                        {{ data.item.icao }}
                                        {{ data.item.city }}
                                      </template>
                                      <template slot="item" slot-scope="data">
                                        <!-- HTML that describe how select should render items when the select is open -->
                                        {{ data.item.icao }}
                                        {{ data.item.city }}
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="1" sm="1">
                                    <v-checkbox
                                      v-model="
                                        editedItem.towflight.manualLandTime
                                      "
                                      :disabled="editedItem.flightType === 10"
                                      color="red"
                                      hide-details
                                    >
                                    </v-checkbox>
                                  </v-col>
                                  <v-col cols="11" sm="5">
                                    <v-text-field type="time"  :disabled="
                                        !editedItem.towflight.manualLandTime ||
                                          editedItem.flightType === 10
                                      "
                            v-model="editedItem.towflight.landingTimeSelector">
                                  </v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      v-model="editedItem.towflight.engineStart"
                                      type="number"
                                      step="0.01"
                                      label="Engine Start"
                                    ></v-text-field>
                                    <a @click="refreshEngineStart">Zähler aktualisieren</a>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      v-model="editedItem.towflight.engineStop"
                                      type="number"
                                      step="0.01"
                                      label="Engine Stop"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                      v-model="
                                        editedItem.towflight
                                          .landingsTakeoffLocation
                                      "
                                      :disabled="
                                        editedItem.towflight.takeoffLocation === editedItem.towflight.landingLocation || editedItem.towflight.takeoffLocation === null
                                      "
                                      type="number"
                                      label="Landungen am Startort"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                      v-model="
                                        editedItem.towflight
                                          .landingsLandingLocation
                                      "
                                      :disabled="
                                       editedItem.towflight.landingLocation === null
                                      "
                                      type="number"
                                      label="Landungen am Landeort"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12">
                                    <v-textarea
                                      outlined
                                      name="TowflightComment"
                                      label="Kommentar"
                                      rows="3"
                                      v-model="editedItem.towflight.comment"
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
              <v-btn color="success" text @click="saveAndTo()" :disabled="disabledWhileLoading || editedItem.takeoffTime != null">
                  Starten
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close()" :disabled="disabledWhileLoading">
                  Schließen
                </v-btn>
                <v-btn color="blue darken-1" text @click="save()" :disabled="disabledWhileLoading">
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <span class="hidden-lg-and-down" v-if="$store.state.GlobalFlightListFilterButtons.hideFinishedFlights && $store.state.OnlineFlightList.length > 0"
            ><p class="font-weight-light; color: Black">
              Abgeschlossene Flüge versteckt! ({{ $store.state.OnlineFlightList.length - computedFlightList.length }})
            </p></span
          >
          <v-spacer></v-spacer>
          <v-switch
              v-model="$store.state.GlobalFlightListFilterButtons.showOnlyMyFlights"
              label="Nur meine Flüge"
              class="hidden-sm-and-down"
              color="indigo darken-3"
              hide-details
            ></v-switch>
            <v-spacer></v-spacer>
                      <v-switch
              v-model="$store.state.GlobalFlightListFilterButtons.hideFinishedFlights"
              label="Abgeschlossene Flüge ausblenden"
              class="hidden-sm-and-down"
              color="orange"
              hide-details
            >
            </v-switch>
            <v-spacer></v-spacer>
            <v-select v-model="tableSchema" :items="availableTableSchemas" item-text="text" item-value="id" dense label="Anzeige-Profil" style="transform: translateY(10px);"></v-select>
          <v-dialog v-model="dialogUpload" max-width="600px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" style="margin-left: 10px;" dark v-bind="attrs" v-on="on">
                <v-icon left>
                  fa-upload
                </v-icon>
                IGC
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">.IGC-Datei Hochladen</v-card-title>
              <v-card-text>
                <v-file-input
                  truncate-length="15"
                  dense
                  v-model="fileUpload"
                  accept=".igc"
                  placeholder=".igc auswählen"
                  clearable
                  outlined
                ></v-file-input>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeUpload"
                  >Abbrechen</v-btn
                >
                <v-btn color="blue darken-1" text @click="processUpload"
                  >Berechnen</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            max-width="600px"
            v-if="lastSelectedTableItem != null"
          >
            <v-card>
              <v-card-title class="text-h5"
                >Bist du sicher, dass du den Flug löschen möchtest?</v-card-title
              >
              <v-card-text>
                <v-container class="title">
                  <v-row
                    >Flugzeug:
                    {{ lastSelectedTableItem.item.callsign
                    }}</v-row
                  >
                  <v-row
                    >Pilot:
                    {{
                      lastSelectedTableItem.item.pilotID != null
                        ? $store.state.PilotList.filter(
                            e => e.userID === lastSelectedTableItem.item.pilotID
                          )[0].firstname +
                          " " +
                          $store.state.PilotList.filter(
                            e => e.userID === lastSelectedTableItem.item.pilotID
                          )[0].lastname
                        : lastSelectedTableItem.item.pilotName
                    }}</v-row
                  >
                  <v-row
                    v-if="
                      lastSelectedTableItem.item.passengerID != null ||
                        lastSelectedTableItem.item.passengerName != null
                    "
                    >Begleiter:
                    {{
                      lastSelectedTableItem.item.passengerID != null
                        ? $store.state.PilotList.filter(
                            e =>
                              e.userID ===
                              lastSelectedTableItem.item.passengerID
                          )[0].firstname +
                          " " +
                          $store.state.PilotList.filter(
                            e =>
                              e.userID ===
                              lastSelectedTableItem.item.passengerID
                          )[0].lastname
                        : lastSelectedTableItem.item.passengerName
                    }}</v-row
                  >
                  <v-row
                    >#ID: {{ lastSelectedTableItem.item.flightID }}</v-row
                  >
                  <v-row class="subtitle-1"
                    >Wenn der Flug zu einem Schleppzug gehört, werden beide
                    Flüge entfernt!</v-row
                  >
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Abbrechen</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogFlightReport"
            max-width="800px"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5"
                >Flug- / Schadensmeldung erstellen</v-card-title
              >
              <v-card-text>
                <v-container class="title">
                  <v-row
                    >                          <v-autocomplete
                            v-model="flightReport.planeID"
                            :items="$store.state.PlaneList"
                            v-on:change="planeSelectEvent"
                            item-text="callsign"
                            clearable
                            outlined
                            dense
                            item-value="planeID"
                            label="Flugzeug"
                          ></v-autocomplete></v-row
                  >
                  <v-row
                    ><v-textarea v-model="flightReport.comment" label="Schadensbeschreibung (Bitte möglichts genau!)"></v-textarea></v-row
                  >
                  <v-row
                    ><v-checkbox v-model="flightReport.grounded" :true-value="1" :false-value="0" label="Soll das Flugzeug bis zur Untersuchung gegrounded werden?" color="red"></v-checkbox></v-row
                  >
                  <v-row class="subtitle-1"
                    ><strong>Diese Meldung wird automatisch an einen verantwortlichen Techniker weitergeleitet.</strong></v-row
                  >
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeFlightReport"
                  >Abbrechen</v-btn
                >
                <v-btn color="success darken-1" text @click="sendFlightReport"
                  >Absenden</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.toLocation="{ item }"
        ><span>{{ item.takeoffLocation }}</span></template
      >
      <template v-slot:item.toTime="{ item }"
        ><span v-if="item.takeoffTime != null"
          >{{ TimestampToTime(item.takeoffTime) }} UTC</span
        >
        <span v-else-if="item.flightType === 9"> Kommt</span>
        <span v-else>
          <v-btn @click="takeoffFlight(item)" :disabled="$store.state.FlightLogDate != moment().format('YYYY-MM-DD')" >TO</v-btn></span
        ></template
      >
      <template v-slot:item.landTime="{ item }"
        ><span v-if="item.landingTime != null"
          >{{ TimestampToTime(item.landingTime) }} UTC</span
        >
        <span v-else-if="item.flightType === 10"> Geht</span>
        <span v-else>
          <v-btn
            :disabled="( item.takeoffTime == null && item.flightType !== 9 ) || item.flightType === 10 || $store.state.FlightLogDate != moment().format('YYYY-MM-DD')"
            @click="landingFlight(item)"
            >LAND</v-btn
          ></span
        ></template
      >
      <template v-slot:item.landLocation="{ item }"
        ><span>{{ item.landingLocation }}</span></template
      >
      <template v-slot:item.editBtn="item"
        ><v-btn icon @click="openEdit(item)"><v-icon size="20" >fa-edit</v-icon></v-btn></template
      >
    </v-data-table>
    <!-- RIGHT CLICK MENU -->
    <v-menu
      v-model="TableMenu.showMenu"
      :position-x="TableMenu.x"
      :position-y="TableMenu.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item dense
          @click="repeatLastSelectedItem"
          v-if="lastSelectedTableItem != null"
          :disabled="
            lastSelectedTableItem.item.takeoffTime == null ||
              lastSelectedTableItem.item.landingTime == null
          "
        >
          <v-list-item-icon>
            <v-icon>fa-redo</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Wiederholen</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="landAndRepeatLastSelectedItem" dense
         v-if="lastSelectedTableItem != null"
        :disabled="
            lastSelectedTableItem.item.takeoffTime == null ||
              lastSelectedTableItem.item.landingTime != null ||
              lastSelectedTableItem.item.flightType == 10
          ">
          <v-list-item-icon>
            <v-icon>fa-road</v-icon>
          </v-list-item-icon>
          <v-list-item-title>LAND & Wiederholen</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="editLastSelectedItem">
          <v-list-item-icon>
            <v-icon>fa-edit</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Bearbeiten</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense
          @click="deleteLastSelectedItem"
          v-if="lastSelectedTableItem != null"
          :disabled="
            lastSelectedTableItem.item.takeoffTime != null ||
              lastSelectedTableItem.item.landingTime != null
          "
        >
          <v-list-item-icon>
            <v-icon>fa-trash-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Löschen</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          @click="openFlightReport" dense
        >
          <v-list-item-icon>
            <v-icon>fa-exclamation-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Schadensbericht erstellen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- RIGHT CLICK MENU  END -->
  </div>
</template>
<style>
.merge-tooltip {
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -15px);
}
.flightLocalColor {
  background-color: rgb(116, 226, 94)
}
.flightInboundColor {
  background-color: rgb(247, 109, 109)
}
.flightOutboundColor {
  background-color: rgb(92, 174, 241)
}
.flightLaunchmethodWinchColor {
  background-color: rgb(116, 226, 94)
}
.flightLaunchmethodTowColor {
  background-color: rgb(247, 109, 109)
}
.flightLaunchmethodSelflaunchColor {
  background-color: rgb(243, 200, 107)
}
.flightPlaneClubColor {
  background-color: rgb(116, 226, 94)
}
.flightPlaneExternColor {
  background-color: rgb(247, 109, 109)
}
.flightTypeTrainingColor {
  background-color: rgb(247, 109, 109)
}
.flightTypeNormalColor {
  background-color: rgb(116, 226, 94)
}
.flightTypeMaintenanceColor {
  background-color: rgb(245, 116, 245)
}
.flightTypeGuestColor {
  background-color: rgb(247, 222, 109)
}
.flightMissingInformationColor {
  background-color: rgb(247, 109, 109)
}
</style>
<script>
import moment from 'moment'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogUpload: false,
    dialogFlightReport: false,
    fileUpload: null,
    disabledWhileLoading: false,
    tableSchema: 0,
    availableTableSchemas: [
      { text: 'Normal', id: 0 },
      { text: 'Unvollständige Flüge', id: 2 },
      { text: 'Flugtyp', id: 3 },
      { text: 'Eigene/Fremde Flugzeuge', id: 4 },
      { text: 'Startart', id: 5 },
      { text: 'Lokalflüge', id: 6 }
    ],
    flightReport: {
      comment: null,
      grounded: 0,
      status: 0,
      planeID: null
    },
    TableMenu: {
      showMenu: false,
      x: null,
      y: null
    },
    additionalHeaders: [
      { text: '', value: 'editBtn' },
      { text: 'Kennz.', align: 'start', value: 'callsign' },
      { text: 'Pilot', value: 'pilot' },
      { text: 'Begleiter', value: 'copilot' },
      { text: 'Flugtyp', value: 'type' },
      { text: 'Startart', value: 'launch' },
      { text: 'Von', value: 'toLocation' },
      { text: 'Startzeit', value: 'toTime' },
      { text: 'Landezeit', value: 'landTime' },
      { text: 'Nach', value: 'landLocation' },
      { text: 'Dauer', value: 'duration' },
      { text: 'Zahlart', value: 'payment' },
      { text: '#ID', value: 'flightID' }

    ],
    headers: [
      { text: 'Kennz.', align: 'start', value: 'callsign' },
      { text: 'Pilot', value: 'pilot' },
      { text: 'Begleiter', value: 'copilot' },
      { text: 'Flugtyp', value: 'type' },
      { text: 'Startart', value: 'launch' },
      { text: 'Von', value: 'toLocation' },
      { text: 'Startzeit', value: 'toTime' },
      { text: 'Landezeit', value: 'landTime' },
      { text: 'Nach', value: 'landLocation' },
      { text: 'Dauer', value: 'duration' },
      { text: 'Zahlart', value: 'payment' },
      { text: '#ID', value: 'flightID' }
    ],
    overwriteWarning: false,
    lastSelectedTableItem: null,
    editedIndex: -1,
    editedItem: {
      planeID: null,
      planeClub: null,
      callsign: null,
      paymentMethod: 1,
      launchMethod: null,
      pilotID: null,
      passengerID: null,
      pilotName: null,
      passengerName: null,
      flightType: null,
      towFlightID: null,
      isTowplane: null,
      takeoffLocation: null,
      landingLocation: null,
      takeoffTime: null,
      takeoffTimeSelector: '',
      landingTime: null,
      landingTimeSelector: '',
      creatorID: null,
      creationTime: null,
      payerID: null,
      payerSliderSplit: [0, 100],
      paySplit: null,
      engineStart: null,
      engineStop: null,
      manualToTime: false,
      manualLandTime: false,
      refuel: null,
      reoil: null,
      forceActCost: null,
      comment: '',
      landingsTakeoffLocation: 0,
      landingsLandingLocation: 0,
      towflight: {
        planeID: null,
        planeClub: null,
        callsign: null,
        paymentMethod: 1,
        launchMethod: 3,
        pilotID: null,
        passengerID: null,
        pilotName: null,
        passengerName: null,
        flightType: 3,
        towFlightID: null,
        isTowplane: 1,
        takeoffLocation: null,
        landingLocation: null,
        takeoffTime: null,
        takeoffTimeSelector: '',
        landingTime: null,
        landingTimeSelector: '',
        creatorID: null,
        creationTime: null,
        payerID: null,
        payerSliderSplit: [0, 100],
        paySplit: null,
        engineStart: null,
        engineStop: null,
        manualToTime: false,
        manualLandTime: false,
        comment: '',
        refuel: null,
        reoil: null,
        forceActCost: null,
        landingsTakeoffLocation: 0,
        landingsLandingLocation: 0
      }
    },
    defaultItem: {
      planeID: null,
      planeClub: null,
      callsign: null,
      paymentMethod: 1,
      launchMethod: null,
      pilotID: null,
      passengerID: null,
      pilotName: null,
      passengerName: null,
      flightType: null,
      towFlightID: null,
      isTowplane: null,
      takeoffLocation: null,
      landingLocation: null,
      takeoffTime: null,
      takeoffTimeSelector: '',
      landingTime: null,
      landingTimeSelector: '',
      creatorID: null,
      creationTime: null,
      payerID: null,
      payerSliderSplit: [0, 100],
      paySplit: null,
      engineStart: null,
      engineStop: null,
      manualToTime: false,
      manualLandTime: false,
      comment: '',
      refuel: null,
      reoil: null,
      forceActCost: null,
      landingsTakeoffLocation: 0,
      landingsLandingLocation: 0,
      towflight: {
        planeID: null,
        planeClub: null,
        callsign: null,
        paymentMethod: 1,
        launchMethod: 3,
        pilotID: null,
        passengerID: null,
        pilotName: null,
        passengerName: null,
        flightType: 3,
        towFlightID: null,
        isTowplane: 1,
        takeoffLocation: null,
        landingLocation: null,
        takeoffTime: null,
        takeoffTimeSelector: '',
        landingTime: null,
        landingTimeSelector: '',
        creatorID: null,
        creationTime: null,
        payerID: null,
        payerSliderSplit: [0, 100],
        paySplit: null,
        engineStart: null,
        engineStop: null,
        manualToTime: false,
        manualLandTime: false,
        comment: '',
        refuel: null,
        reoil: null,
        forceActCost: null,
        landingsTakeoffLocation: 0,
        landingsLandingLocation: 0
      }
    }
  }),
  components: {
    VueSlider
  },
  computed: {
    computedHeaders () {
      if (this.computedIsAppleDevice) {
        return this.additionalHeaders
      }
      return this.headers
    },
    computedPaymentTypeRecommendation () {
      var list = []
      if (this.editedItem.flightType != null) {
        switch (parseInt(this.editedItem.flightType)) {
          case (7): // Gastflug
            list = [3, 2, 7] // Gutschein, Bar
            break
          case (6): // Gutscheinflug
            list = [2] // Gutschein
            break
          case (5): // Werkstattflug
            list = [6] // Verein Zahlt
            break
          case (3): // Normal
            list = [5] // Andere / Mehrere
            break
          default:
            break
        }
      }
      if (list.includes(this.editedItem.paymentMethod)) { // Remove current selected from list if present
        list.splice(list.indexOf(this.editedItem.paymentMethod), 1)
      }
      return list
    },
    computedPassengerRecomendation () {
      var list = []
      if (this.editedItem.planeID != null) {
        var tmp = this.computedPlaneList.filter(e => e.planeID === this.editedItem.planeID)[0].instructors
        if (tmp != null && tmp !== undefined && tmp.length > 0) list = tmp.split(',')
      }
      return list
    },
    computedMostRecentFlightTypes () {
      var list = []
      if (this.$store.state.FlightTypeList != null && this.computedPlaneList != null) {
        const plane = this.computedPlaneList.filter(e => e.planeID === this.editedItem.planeID)[0]
        const seats = plane.seats
        list = this.$store.state.FlightTypeList.filter(e => e.minNumberOfPilots <= seats).slice(0, 3)
      }
      return list
    },
    computedMostRecentPlanes () {
      var list = []
      if (this.$store.state.PlaneList != null) {
        list = this.$store.state.PlaneList.slice(0, 3)
      }
      return list
    },
    computedFlightList () {
      var flightList = this.$store.state.OnlineFlightList

      if (this.$store.state.GlobalFlightListFilterButtons.showOnlyMyFlights) {
        flightList = flightList.filter(e => e.pilotID === this.$store.state.User.userID)
      }

      if (this.$store.state.FliegerlagerMode) {
        flightList = flightList.filter(e => e.takeoffLocation === this.$store.state.presets.defaultLocation || e.takeoffLocation === null)
      }
      if (this.$store.state.GlobalFlightListFilterButtons.hideFinishedFlights) {
        // Filter Finished Flights
        flightList = flightList.filter(
          e =>
            (e.landingTime === null && e.takeoffTime === null) ||
            (e.takeoffTime != null &&
              e.landingTime === null &&
              e.flightType !== 10)
        )
      }

      var mergedFlightlist = []

      flightList.forEach(item => {
        /*
        var tmpFlight = {}
        tmpFlight.callsign = item.planeID !== null ? this.$store.state.PlaneList.filter(e => e.planeID === item.planeID)[0].callsign + ' (' + this.$store.state.PlaneList.filter(e => e.planeID === item.planeID)[0].planeName + ')' : item.callsign
        tmpFlight.pilot = item.pilotID != null ? this.$store.state.PilotList.filter(e => e.userID === item.pilotID)[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === item.pilotID)[0].lastname : item.pilotName
        tmpFlight.copilot = item.passengerID != null ? this.$store.state.PilotList.filter(e => e.userID === item.passengerID)[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === item.passengerID)[0].lastname : item.passengerName
        tmpFlight.type = item.flightType != null ? this.$store.state.FlightTypeList.filter(e => e.typeID === item.flightType)[0].title : 'fail'
        tmpFlight.launch = item.launchMethod != null ? this.$store.state.LaunchmethodList.filter(e => e.launchID === item.launchMethod)[0].title : 'fail'
        tmpFlight.toLocation = item.takeoffLocation
        tmpFlight.toTime = item.takeoffTime
        tmpFlight.landTime = item.landingTime
        tmpFlight.landLocation = item.landingLocation
        tmpFlight.duration = item.takeoffTime !== null && item.landingTime !== null ? this.calculateTimeDifference(item.takeoffTime, item.landingTime) : (item.takeoffTime != null && item.landingTime == null && item.flightType !== 10 ? this.calculateTimeDifference(item.takeoffTime, moment().utc().format('YYYY-MM-DD HH:mm:ss')) : '---')
        tmpFlight.payment = this.$store.state.PaymentTypeList.filter(e => e.paymenttypeID === item.paymentMethod)[0].title

        // tmpFlight.flightID = item.flightID
        */
        item.callsign =
          item.planeID !== null
            ? this.$store.state.PlaneList.filter(
              e => e.planeID === item.planeID
            )[0].callsign +
              ' (' +
              this.$store.state.PlaneList.filter(
                e => e.planeID === item.planeID
              )[0].planeName +
              ')'
            : item.callsign
        item.pilot =
          item.pilotID != null
            ? this.$store.state.PilotList.filter(
              e => e.userID === item.pilotID
            )[0].firstname +
              ' ' +
              this.$store.state.PilotList.filter(
                e => e.userID === item.pilotID
              )[0].lastname
            : item.pilotName
        item.copilot =
          item.passengerID != null
            ? this.$store.state.PilotList.filter(
              e => e.userID === item.passengerID
            )[0].firstname +
              ' ' +
              this.$store.state.PilotList.filter(
                e => e.userID === item.passengerID
              )[0].lastname
            : item.passengerName
        item.type =
          item.flightType != null
            ? this.$store.state.FlightTypeList.filter(
              e => e.typeID === item.flightType
            )[0].title
            : 'fail'
        item.launch =
          item.launchMethod != null
            ? this.$store.state.LaunchmethodList.filter(
              e => e.launchID === item.launchMethod
            )[0].title
            : 'fail'
        item.toLocation = item.takeoffLocation
        item.toTime = item.takeoffTime
        item.landTime = item.landingTime
        item.landLocation = item.landingLocation
        item.duration =
          item.takeoffTime !== null && item.landingTime !== null
            ? this.calculateTimeDifference(item.takeoffTime, item.landingTime)
            : item.takeoffTime != null &&
              item.landingTime == null &&
              item.flightType !== 10
              ? this.calculateTimeDifference(
                item.takeoffTime,
                moment()
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss')
              )
              : '---'
        item.payment = this.$store.state.PaymentTypeList.filter(
          e => e.paymenttypeID === item.paymentMethod
        )[0].title

        mergedFlightlist.push(item)
      })

      return mergedFlightlist
    },
    computedAirportList () {
      var airports = []
      var AirportList = this.$store.state.AirportList

      for (var i = 0; i < AirportList.length; i++) {
        airports.push({
          icao: AirportList[i].ICAO,
          city: AirportList[i].cityName
        })
      }
      return airports
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Neuer Flug' : 'Flug bearbeiten'
    },
    computedFlightTypeList () {
      if (this.editedItem.planeID !== null) {
        var seatsInPlane = this.$store.state.PlaneList.filter(
          e => e.planeID === this.editedItem.planeID
        )[0].seats
        return this.$store.state.FlightTypeList.filter(
          e => seatsInPlane >= e.minNumberOfPilots
        )
      } else {
        return this.$store.state.FlightTypeList
      }
    },
    computedPilotList () {
      if (this.editedItem.passengerID != null) {
        return this.computedPilotListAll.filter(
          e =>
            e.userID !== this.editedItem.passengerID &&
            e.userID !== this.editedItem.towflight.pilotID &&
            e.userID !== this.editedItem.towflight.passengerID
        )
      } else {
        return this.computedPilotListAll
      }
    },
    computedPilotListAll () {
      if (window.navigator.userAgent.toUpperCase().includes('GLEITSTEINSTARTWAGEN')) {
        return this.$store.state.PilotList.filter(e => [...new Set(this.computedAttendancePilotList.map(item => item.userID))].includes(e.userID))
      }
      return this.$store.state.PilotList
    },
    computedNoPilotListText () {
      if (window.navigator.userAgent.toUpperCase().includes('GLEITSTEINSTARTWAGEN')) {
        return 'Siehe Anwesenheitsliste'
      }
      return 'Keine Daten...'
    },
    computedPlaneList () {
      return this.$store.state.PlaneList
    },
    computedAttendancePilotList () {
      return this.$store.state.AttendanceList
    },
    computedCopilotList () {
      if (this.editedItem.pilotID != null) {
        return this.computedPilotListAll.filter(
          e =>
            e.userID !== this.editedItem.pilotID &&
            e.userID !== this.editedItem.towflight.pilotID &&
            e.userID !== this.editedItem.towflight.passengerID
        )
      } else {
        return this.computedPilotListAll
      }
    },
    maxPilotsReached () {
      if (this.editedItem.planeID != null) {
        if (
          this.$store.state.PlaneList.filter(
            e => e.planeID === this.editedItem.planeID
          )[0].seats < 2
        ) {
          return true
        } else {
          if (this.editedItem.flightType != null) {
            if (
              this.$store.state.FlightTypeList.filter(
                e => e.typeID === this.editedItem.flightType
              )[0].maxNumberOfPilots < 2
            ) {
              return true
            }
            return false
          }
          return false
        }
      }
      return false
    },
    maxTowPilotsReached () {
      if (this.editedItem.towflight.planeID != null) {
        if (
          this.$store.state.PlaneList.filter(
            e => e.planeID === this.editedItem.towflight.planeID
          )[0].seats < 2
        ) {
          return true
        } else {
          if (this.editedItem.towflight.flightType != null) {
            if (
              this.$store.state.FlightTypeList.filter(
                e => e.typeID === this.editedItem.towflight.flightType
              )[0].maxNumberOfPilots < 2
            ) {
              return true
            }
            return false
          }
          return false
        }
      }
      return false
    },
    computedPlaneHasEngine () {
      if (this.editedItem.planeID != null) {
        var callsign = this.$store.state.PlaneList.filter(
          e => e.planeID === this.editedItem.planeID
        )[0].callsign
        if (this.isNumber(callsign[2])) {
          return false
        }
        return true
      } else {
        return false
      }
    },
    computedTowPaysplitSliderProcess () {
      var array = []
      for (var i = 0; i < this.editedItem.towflight.payerID.length; i++) {
        var array1 = []
        array1.push(this.editedItem.towflight.payerSliderSplit[i])
        array1.push(this.editedItem.towflight.payerSliderSplit[i + 1])
        array.push(array1)
      }
      return val => array
    },
    computedPaysplitSliderProcess () {
      var array = []
      for (var i = 0; i < this.editedItem.payerID.length; i++) {
        var array1 = []
        array1.push(this.editedItem.payerSliderSplit[i])
        array1.push(this.editedItem.payerSliderSplit[i + 1])
        array.push(array1)
      }
      return val => array
    },
    computedTowpilotList () {
      return this.$store.state.PilotList.filter(
        e =>
          e.userID !== this.editedItem.pilotID &&
          e.userID !== this.editedItem.passengerID &&
          e.userID !== this.editedItem.towflight.passengerID
      )
    },
    computedTowcopilotList () {
      return this.$store.state.PilotList.filter(
        e =>
          e.userID !== this.editedItem.pilotID &&
          e.userID !== this.editedItem.passengerID &&
          e.userID !== this.editedItem.towflight.pilotID
      )
    },
    computedTowplaneList () {
      return this.$store.state.PlaneList.filter(e => e.towplane === 1)
    },
    computedIsAppleDevice () {
      return /iPhone|Mac/i.test(navigator.userAgent)
    }
  },
  watch: {
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    async refreshEngineStart () {
      await this.$store.dispatch('loadPlaneList')
      this.editedItem.engineStart = this.$store.state.PlaneList.filter(e => e.planeID === this.editedItem.planeID)[0].lastMotorStop
      if (this.editedItem.towflight.planeID !== null) {
        await this.$store.dispatch('loadPlaneList')
        this.editedItem.towflight.engineStart = this.$store.state.PlaneList.filter(e => e.planeID === this.editedItem.towflight.planeID)[0].lastMotorStop
      }
    },
    setPilotAndPassengerAsPaySplit () {
      this.editedItem.payerID = [this.editedItem.pilotID, this.editedItem.passengerID]
      this.updateDots()
    },
    openDialog () {
      this.dialog = true
      if (this.editedIndex === -1 && localStorage.getItem('recentFlightObject') !== null) {
        // console.log('load recent flight to dialog')
        this.editedItem = JSON.parse(window.localStorage.getItem('recentFlightObject'))
      }
    },
    resetForm () {
      // console.log('reset form, remove recent flight and reset editedItem')
      window.localStorage.removeItem('recentFlightObject')
      this.editedItem = Object.assign({}, this.defaultItem)
    },
    openFlightReport () {
      if (this.lastSelectedTableItem != null) {
        this.flightReport.planeID = this.lastSelectedTableItem.item.planeID
      }
      this.dialogFlightReport = true
    },
    closeFlightReport () {
      this.dialogFlightReport = false
      this.flightReport.comment = null
      this.flightReport.grounded = 0
      this.flightReport.planeID = null
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.lastSelectedTableItem = null
    },
    sendFlightReport () {
      this.$store
        .dispatch('fileFlightReport', this.flightReport)
        .then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            alert('Meldung gespeichert!')
            this.closeFlightReport()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
    },
    async saveAndTo () {
      this.editedItem.manualToTime = true
      this.editedItem.takeoffTimeSelector = moment().utc().format('HH') + ':' + moment().utc().format('mm')
      console.log(this.editedItem)
      this.save()
    },
    async landAndRepeatLastSelectedItem () {
      await this.landingFlight(this.lastSelectedTableItem.item)
      this.repeatLastSelectedItem()
    },
    planeSelectEvent: async function (e) {
      if (this.editedItem.planeID !== null) {
        if (this.computedPlaneHasEngine) {
          await this.$store.dispatch('loadPlaneList')
          this.editedItem.launchMethod = 6
          this.editedItem.engineStart = this.$store.state.PlaneList.filter(e => e.planeID === this.editedItem.planeID)[0].lastMotorStop
        } else {
          this.editedItem.launchMethod = 1
        }
      }
    },
    towPlaneSelectEvent: async function (e) {
      if (this.editedItem.towflight.planeID !== null) {
        await this.$store.dispatch('loadPlaneList')
        this.editedItem.towflight.engineStart = this.$store.state.PlaneList.filter(e => e.planeID === this.editedItem.towflight.planeID)[0].lastMotorStop
      }
    },
    changeTakeoffTimeSelector: function () {
      this.editedItem.towflight.takeoffTimeSelector = this.editedItem.takeoffTimeSelector
    },
    manualToTimeChangeEvent: function () {
      this.editedItem.towflight.manualToTime = this.editedItem.manualToTime
    },
    changeTakeoffLocation: function () {
      this.editedItem.towflight.takeoffLocation = this.editedItem.takeoffLocation
    },
    filterPilotObject (item, queryText, itemText) {
      return (
        item.firstname
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.lastname
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    filterAirportObject (item, queryText, itemText) {
      return (
        item.icao.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.city.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      )
    },
    moment: function () {
      return moment()
    },
    convertCommaSeperatedIntegerListToArray (string) {
      //  console.log(string)
      var array = []
      if (string.indexOf(',') > -1) {
        array = string.split(',')

        for (var i = 0; i < array.length; i++) {
          if (!isNaN(array[i])) {
            array[i] = parseInt(array[i])
          }
        }
      }
      return array
    },
    sliderChange () {
      this.editedItem.payerSliderSplit[0] = 0
      this.editedItem.payerSliderSplit[
        this.editedItem.payerSliderSplit.length - 1
      ] = 100

      var array = []
      for (var x = 0; x < this.editedItem.payerSliderSplit.length - 1; x++) {
        array.push(
          this.editedItem.payerSliderSplit[x + 1] -
            this.editedItem.payerSliderSplit[x]
        )
      }
      this.editedItem.paySplit = array
    },
    towSliderChange () {
      this.editedItem.towflight.payerSliderSplit[0] = 0
      this.editedItem.towflight.payerSliderSplit[
        this.editedItem.towflight.payerSliderSplit.length - 1
      ] = 100

      var array = []
      for (
        var x = 0;
        x < this.editedItem.towflight.payerSliderSplit.length - 1;
        x++
      ) {
        array.push(
          this.editedItem.towflight.payerSliderSplit[x + 1] -
            this.editedItem.towflight.payerSliderSplit[x]
        )
      }
      this.editedItem.towflight.paySplit = array
    },
    updateDots () {
      //  console.log('upddateDotsTriggered')

      var numberOfPayers = this.editedItem.payerID.length
      var array = []
      var parts = 100 / numberOfPayers
      array.push(0)
      for (var i = 1; i <= numberOfPayers - 1; i++) {
        array.push(Math.round(100 - i * parts))
      }
      array.push(100)

      //  console.log(array)
      // copy values to towflight if f-schlepp and calc these dots too
      if (this.editedItem.launchMethod === 2) {
        this.editedItem.towflight.paymentMethod = this.editedItem.paymentMethod
        this.editedItem.towflight.payerID = this.editedItem.payerID
        this.editedItem.towflight.payerSliderSplit = array
      }

      this.editedItem.payerSliderSplit = array
    },
    updateTowDots () {
      var numberOfPayers = this.editedItem.towflight.payerID.length
      var array = []
      var parts = 100 / numberOfPayers
      array.push(0)
      for (var i = 1; i <= numberOfPayers - 1; i++) {
        array.push(Math.round(100 - i * parts))
      }
      array.push(100)
      this.editedItem.towflight.payerSliderSplit = array
    },
    openEdit (item) {
      console.log(item)
      this.lastSelectedTableItem = item
      this.editLastSelectedItem()
    },
    rightClickHandler (event, item) {
      // do something with event and/or item
      //  console.log(item)
      //  console.log(item)
      this.TableMenu.x = event.clientX
      this.TableMenu.y = event.clientY
      this.TableMenu.showMenu = true
      this.lastSelectedTableItem = item
    },
    TimestampToTime: function (timestamp) {
      return moment(timestamp).format('HH:mm')
    },
    calculateTimeDifference: function (takeoff, landing) {
      var ms = moment(landing).diff(moment(takeoff))
      var d = moment.duration(ms)
      return Math.floor(d.asHours()) + moment.utc(ms).format(':mm')
    },
    async editLastSelectedItem () {
      this.editedIndex = this.lastSelectedTableItem.index
      this.editedItem = Object.assign({}, this.lastSelectedTableItem.item)
      this.editedItem.towflight = Object.assign({}, this.defaultItem.towflight)

      if (this.editedItem.paySplit != null && isNaN(this.editedItem.payerID)) {
        //  console.log(this.editedIndex.paySplit)
        this.editedItem.paySplit = this.convertCommaSeperatedIntegerListToArray(
          this.editedItem.paySplit
        )
      }
      var payerSliderArray = []
      var currentSliderPosition = 0
      payerSliderArray.push(0)

      if (
        this.editedItem.payerID != null &&
        isNaN(this.editedItem.payerID) &&
        this.editedItem.payerID[0] !== 'G'
      ) {
        this.editedItem.payerID = this.convertCommaSeperatedIntegerListToArray(
          this.editedItem.payerID
        )

        if (this.editedItem.payerID.length > 1) {
          for (var x = 0; x < this.editedItem.paySplit.length - 1; x++) {
            payerSliderArray.push(
              currentSliderPosition + this.editedItem.paySplit[x]
            )
            currentSliderPosition += this.editedItem.paySplit[x]
          }
        }

        payerSliderArray.push(100)
      }
      this.editedItem.payerSliderSplit = payerSliderArray

      this.editedItem.takeoffTimeSelector = moment(this.editedItem.takeoffTime).format('HH') + ':' + moment(this.editedItem.takeoffTime).format('mm')
      this.editedItem.landingTimeSelector = moment(this.editedItem.landingTime).format('HH') + ':' + moment(this.editedItem.landingTime).format('mm')

      this.editedItem.manualLandTime = this.editedItem.landingTime !== null
      this.editedItem.manualToTime = this.editedItem.takeoffTime !== null

      if (this.computedPlaneHasEngine && isNaN(this.editedItem.engineStart)) {
        await this.$store.dispatch('loadPlaneList')
        this.editedItem.engineStart = this.$store.state.PlaneList.filter(e => e.planeID === this.editedItem.planeID)[0].lastMotorStop
      }

      this.dialog = true
    },
    repeatLastSelectedItem () {
      var MainFlight = []
      var TowFlight = []

      //  console.log(this.lastSelectedTableItem)
      if (this.lastSelectedTableItem.item.isTowplane) {
        //  console.log('Is a Towplane')
        Object.assign(TowFlight, this.lastSelectedTableItem.item)
        Object.assign(MainFlight, this.computedFlightList.filter(
          e => e.towFlightID === TowFlight.flightID
        )[0])
      } else if (this.lastSelectedTableItem.item.launchMethod === 2) {
        //  console.log('Is a Glider in Tow')
        Object.assign(MainFlight, this.lastSelectedTableItem.item)
        Object.assign(TowFlight, this.computedFlightList.filter(
          e => e.flightID === MainFlight.towFlightID
        )[0])
      } else {
        //  console.log('Just some Plane')
        Object.assign(MainFlight, this.lastSelectedTableItem.item)
      }

      // REMOVE FLIGHT ID*s and Times generated from Database
      delete MainFlight.flightID
      delete MainFlight.towFlightID
      delete MainFlight.creatorID
      delete MainFlight.creationTime
      delete TowFlight.flightID
      delete TowFlight.creatorID
      delete TowFlight.creationTime

      //  REMOVE Landing and takeoff Times
      MainFlight.takeoffTime = null
      MainFlight.landingTime = null
      MainFlight.landingLocation = null
      MainFlight.landingsLandingLocation = 0
      MainFlight.landingsTakeoffLocation = 0
      MainFlight.engineStart = null
      MainFlight.engineStop = null
      MainFlight.refuel = null
      MainFlight.reoil = null

      TowFlight.takeoffTime = null
      TowFlight.landingTime = null
      TowFlight.landingLocation = null
      TowFlight.landingsLandingLocation = 0
      TowFlight.landingsTakeoffLocation = 0
      TowFlight.engineStart = null
      TowFlight.engineStop = null
      TowFlight.refuel = null
      TowFlight.reoil = null

      // ADD IF FOR OTHER VALUES THEN "int,int"
      if (MainFlight.paySplit !== null && isNaN(MainFlight.paySplit)) {
        MainFlight.paySplit = this.convertCommaSeperatedIntegerListToArray(
          MainFlight.paySplit
        )
      }

      if (MainFlight.payerID !== null && isNaN(MainFlight.payerID)) {
        MainFlight.payerID = this.convertCommaSeperatedIntegerListToArray(
          MainFlight.payerID
        )
      }

      if (TowFlight.isTowplane === 1) {
        if (TowFlight.paySplit !== null && isNaN(TowFlight.paySplit)) {
          TowFlight.paySplit = this.convertCommaSeperatedIntegerListToArray(
            TowFlight.paySplit
          )
        }

        if (TowFlight.payerID !== null && isNaN(TowFlight.payerID)) {
          TowFlight.payerID = this.convertCommaSeperatedIntegerListToArray(
            TowFlight.payerID
          )
        }
      }

      // GENERATE PAYER SLIDER SPLITS
      if (
        MainFlight.paySplit !== null &&
        Array.isArray(MainFlight.paySplit) &&
        MainFlight.paySplit.length > 1
      ) {
        var tmpProcessArray = []

        tmpProcessArray.push(0)

        for (var z = 0; z < MainFlight.paySplit.length - 1; z++) {
          tmpProcessArray.push(MainFlight.paySplit[z])
        }
        tmpProcessArray.push(100)
        MainFlight.payerSliderSplit = tmpProcessArray
      }

      if (TowFlight.isTowplane === 1) {
        if (
          TowFlight.paySplit !== null &&
          Array.isArray(TowFlight.paySplit) &&
          TowFlight.paySplit.length > 1
        ) {
          var tmpProcessTowArray = []

          tmpProcessTowArray.push(0)

          for (var x = 0; x < TowFlight.paySplit.length - 1; x++) {
            tmpProcessTowArray.push(TowFlight.paySplit[x])
          }
          tmpProcessTowArray.push(100)
          TowFlight.payerSliderSplit = tmpProcessTowArray
        }
      }

      MainFlight.towflight = Object.assign({}, TowFlight)
      this.editedItem = Object.assign({}, MainFlight)

      this.dialog = true
    },
    deleteLastSelectedItem () {
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.$store
        .dispatch('deleteFlight', this.lastSelectedTableItem.item)
        .then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            this.$store.dispatch('syncFlightTableAndReinitTimer')
            this.closeDelete()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
    },
    closeFormExtern () {
      this.close(true)
    },
    close (removeLocalStorage = false) {
      this.dialog = false
      this.overwriteWarning = false
      if (this.editedIndex === -1 && !removeLocalStorage) { // Wenn beim anlegen eines neuen Fluges das Modal geschlossen wird
        // console.log('save dialog as recent flight')
        window.localStorage.setItem('recentFlightObject', JSON.stringify(this.editedItem)) // Speichern der ausgefüllten Form in Localstorage
      } else if (removeLocalStorage) {
        // console.log('remove recent flight')
        window.localStorage.removeItem('recentFlightObject')
      }

      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.lastSelectedTableItem = null
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeUpload () {
      this.fileUpload = null
      this.dialogUpload = false
    },
    async processUpload () {
      var reader = new FileReader()
      var self = this
      reader.readAsText(this.fileUpload, 'UTF-8')
      reader.onload = function (evt) {
        // TODO ADD MORE CODE TO GET FILE NAME AND THEN SEND THIS F**KING SHIT TO THE API!!!
        self.$store
          .dispatch('parseIGCFile', evt.target.result)
          .then(parsedFlight => {
            //  console.log(parsedFlight)
            if (parseInt(parsedFlight.data.noErrors) !== 0) {
              self.$store.commit('setApiErrorResponse', parsedFlight.data)
              return
            }
            // IGC VALID
            if (parseInt(parsedFlight.data.result.planeID) === -1) {
              self.editedItem.callsign = parsedFlight.data.result.callsign
            } else {
              self.editedItem.planeID = parseInt(
                parsedFlight.data.result.planeID
              )
            }

            if (self.planeHasEngine(parsedFlight.data.result.callsign)) {
              self.editedItem.launchMethod = 6
            } else {
              self.editedItem.launchMethod = 1
            }

            self.editedItem.takeoffLocation =
              parsedFlight.data.result.takeoffLocation
            self.editedItem.landingLocation =
              parsedFlight.data.result.landingLocation

            self.editedItem.manualToTime = true
            self.editedItem.takeoffTime = parsedFlight.data.result.takeoffTime
            self.editedItem.takeoffTimeSelector = moment(self.editedItem.takeoffTime).format('HH') + ':' + moment(self.editedItem.takeoffTime).format('mm')

            self.editedItem.manualLandTime = true
            self.editedItem.landingTime = parsedFlight.data.result.landingTime
            self.editedItem.landingTimeSelector = moment(self.editedItem.landingTime).format('HH') + ':' + moment(self.editedItem.landingTime).format('mm')

            self.editedItem.landingsLandingLocation += 1

            self.dialog = true
            self.closeUpload()
          })
        //  console.log(evt.target.result)
      }
      reader.onerror = function (evt) {
        //  console.log('error reading file')
      }
    },
    async save () {
      var FlightObject = Object.assign({}, this.editedItem)

      var TowFlight = Object.assign({}, FlightObject.towflight)
      delete FlightObject.towflight

      /*
      Times
      */
      if (FlightObject.takeoffTimeSelector === '') FlightObject.takeoffTimeSelector = null
      if (FlightObject.landingTimeSelector === '') FlightObject.landingTimeSelector = null
      if (TowFlight.takeoffTimeSelector === '') TowFlight.takeoffTimeSelector = null
      if (TowFlight.landingTimeSelector === '') TowFlight.landingTimeSelector = null

      if (FlightObject.manualToTime) {
        FlightObject.takeoffTime = moment(this.$store.state.FlightLogDate + ' ' + FlightObject.takeoffTimeSelector).format('YYYY-MM-DD HH:mm:ss')
        if (FlightObject.takeoffLocation === null) FlightObject.takeoffLocation = this.$store.state.presets.defaultLocation
      } else {
        FlightObject.takeoffTime = null
      }
      if (FlightObject.manualLandTime) {
        FlightObject.landingTime = moment(this.$store.state.FlightLogDate + ' ' + FlightObject.landingTimeSelector).format('YYYY-MM-DD HH:mm:ss')
        if (FlightObject.landingLocation === null) FlightObject.landingLocation = this.$store.state.presets.defaultLocation

        if (FlightObject.landingsLandingLocation === 0) {
          FlightObject.landingsLandingLocation =
            FlightObject.landingsLandingLocation + 1
        }
      } else {
        FlightObject.landingTime = null
      }
      if (TowFlight.manualToTime) {
        TowFlight.takeoffTime = moment(this.$store.state.FlightLogDate + ' ' + TowFlight.takeoffTimeSelector).format('YYYY-MM-DD HH:mm:ss')
        if (TowFlight.takeoffLocation === null) TowFlight.takeoffLocation = this.$store.state.presets.defaultLocation
      } else {
        TowFlight.takeoffTime = null
      }
      if (TowFlight.manualLandTime) {
        TowFlight.landingTime = moment(this.$store.state.FlightLogDate + ' ' + TowFlight.landingTimeSelector).format('YYYY-MM-DD HH:mm:ss')
        if (TowFlight.landingLocation === null) TowFlight.landingLocation = this.$store.state.presets.defaultLocation

        if (TowFlight.landingsLandingLocation === 0) {
          TowFlight.landingsLandingLocation =
            TowFlight.landingsLandingLocation + 1
        }
      } else {
        TowFlight.landingTime = null
      }

      /*
      replace engine times
      */

      if (
        FlightObject.engineStart != null && typeof FlightObject.engineStart === 'string' &&
        FlightObject.engineStart.indexOf(',') !== -1
      ) {
        FlightObject.engineStart = FlightObject.engineStart.replace(',', '.')
      }
      if (
        FlightObject.engineStop != null && typeof FlightObject.engineStop === 'string' &&
        FlightObject.engineStop.indexOf(',') !== -1
      ) {
        FlightObject.engineStop = FlightObject.engineStop.replace(',', '.')
      }
      if (
        TowFlight.engineStart != null && typeof TowFlight.engineStart === 'string' &&
        TowFlight.engineStart.indexOf(',') !== -1
      ) {
        TowFlight.engineStart = TowFlight.engineStart.replace(',', '.')
      }
      if (
        TowFlight.engineStop != null && typeof TowFlight.engineStart === 'string' &&
        TowFlight.engineStop.indexOf(',') !== -1
      ) {
        TowFlight.engineStop = TowFlight.engineStop.replace(',', '.')
      }
      /*
      Payment
      */
      if (FlightObject.payerID !== null) {
        if (Array.isArray(FlightObject.payerID)) {
          if (
            FlightObject.paySplit === null ||
            (FlightObject.payerID.length > 1 && FlightObject.paySplit === 100)
          ) {
            FlightObject.paySplit = []
            var parts = Math.round(100 / FlightObject.payerID.length)
            for (var i = 0; i < FlightObject.payerID.length; i++) {
              FlightObject.paySplit.push(parts)
            }
          } else if (FlightObject.payerID.length === 1) {
            FlightObject.paySplit = [100]
          }
          var paySplitString = ''
          FlightObject.paySplit.forEach(e => {
            paySplitString += e + ','
          })
          FlightObject.paySplit = paySplitString //  .replace(/,\s*$/, '')
          TowFlight.paySplit = paySplitString // Setze gleichen Payersplit bei F-Schlepp
          var payerIDstring = ''
          FlightObject.payerID.forEach(e => {
            payerIDstring += e + ','
          })
          FlightObject.payerID = payerIDstring // .replace(/,\s*$/, '')
          TowFlight.payerID = payerIDstring // Setze gleichen Payersplit bei F-Schlepp
        }
      } else if (FlightObject.flightType === 5) {
        // Werkstatt flug
        FlightObject.paymentMethod = 6
      } else {
        // Normal => Pilot = Zahler
        FlightObject.payerID = FlightObject.pilotID
        FlightObject.paySplit = '100,'
      }

      // Wenn Zahlart Standart & kein schleppflugzeug ==> Pilot zahlt
      if (FlightObject.paymentMethod === 1 && FlightObject.launchMethod !== 3) {
        FlightObject.payerID = FlightObject.pilotID
        FlightObject.paySplit = '100,'
      }
      if (
        (FlightObject.paymentMethod === 2 ||
          FlightObject.paymentMethod === 3 ||
          FlightObject.paymentMethod === 4) &&
        FlightObject.payerID[0] !== 'G'
      ) {
        FlightObject.payerID = 'G' + FlightObject.payerID
      }
      if (
        (TowFlight.paymentMethod === 2 ||
          TowFlight.paymentMethod === 3 ||
          TowFlight.paymentMethod === 4) &&
        TowFlight.payerID[0] !== 'G'
      ) {
        TowFlight.payerID = 'G' + TowFlight.payerID
      }
      /*
      Launchmethods
      */
      // if (FlightObject.launchMethod === 2) {
      //   if (TowFlight.manualToTime) {
      //     TowFlight.takeoffTime = moment(TowFlight.takeoffTime)
      //       .set({
      //         h: TowFlight.takeoffTimeSelector.HH,
      //         m: TowFlight.takeoffTimeSelector.mm
      //       })
      //       .format('YYYY-MM-DD HH:mm:ss')
      //   }
      //   if (TowFlight.manualLandTime) {
      //     TowFlight.landingTime = moment(TowFlight.landingTime)
      //       .set({
      //         h: TowFlight.landingTimeSelector.HH,
      //         m: TowFlight.landingTimeSelector.mm
      //       })
      //       .format('YYYY-MM-DD HH:mm:ss')
      //   }
      // }

      if (FlightObject.launchMethod === 2 && isNaN(TowFlight.payerID)) {
        if (TowFlight.payerID !== null) {
          if (Array.isArray(TowFlight.payerID)) {
            if (
              TowFlight.paySplit === null ||
              (TowFlight.payerID.length > 1 && TowFlight.paySplit === 100)
            ) {
              TowFlight.paySplit = []
              var partsTow = Math.round(100 / TowFlight.payerID.length)
              for (var x = 0; x < TowFlight.payerID.length; x++) {
                TowFlight.paySplit.push(partsTow)
              }
            } else if (TowFlight.payerID.length === 1) {
              TowFlight.paySplit = [100]
            }
            var paySplitStringTow = ''
            TowFlight.paySplit.forEach(e => {
              paySplitStringTow += e + ','
            })
            TowFlight.paySplit = paySplitStringTow //  .replace(/,\s*$/, '')
            var payerIDstringTow = ''
            TowFlight.payerID.forEach(e => {
              payerIDstringTow += e + ','
            })
            TowFlight.payerID = payerIDstringTow // .replace(/,\s*$/, '')
          }
        } else if (TowFlight.flightType === 5) {
          // Werkstatt flug
          TowFlight.paymentMethod = 6
        } else {
          // Normal => Pilot = Zahler
          TowFlight.payerID = TowFlight.pilotID
          TowFlight.paySplit = '100,'
        }
      } else if (TowFlight.flightType === 5) {
        TowFlight.paymentMethod = 6
      } else {
        TowFlight.payerID = FlightObject.pilotID
      }

      /*
      FlightTypes
      */
      if (FlightObject.flightType === 1) {
        FlightObject.passengerID = null
        FlightObject.passengerName = ''
      }
      // console.log(FlightObject)
      // console.log(TowFlight)
      //  this.disabledWhileLoading = true       // DISABLE SAVE BUTTON WHILE LOADING
      //  console.log(FlightObject)
      if (this.editedIndex > -1) {
        // EDIT
        // VALIDATE MAIN FLIGHT
        //  console.log('VALIDATE EDITED MAIN-FLIGHT')
        var EDITmainFlightValidation = await this.$store.dispatch(
          'validateFlight',
          FlightObject
        )
        //  console.log(EDITmainFlightValidation)
        if ((EDITmainFlightValidation.data.noErrors === 0 && EDITmainFlightValidation.data.noWarnings === 0) || this.overwriteWarning) {
          //  console.log('Flight is VALID!')
          // UPDATE MAIN FLIGHT
          this.$store
            .dispatch('updateFlight', FlightObject)
            .then(updatedFlight => {
              if (!this.overwriteWarning) {
                if (updatedFlight.data.noErrors !== 0 || updatedFlight.data.noWarnings !== 0) {
                  this.$store.commit('setApiErrorResponse', updatedFlight.data)
                  return
                }
              }
              this.$store.dispatch('syncFlightTableAndReinitTimer')
              this.close()
            })
        } else {
          this.$store.commit(
            'setApiErrorResponse',
            EDITmainFlightValidation.data
          )
        }
      } else {
        FlightObject.creationTime = moment()
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
        FlightObject.creatorID = this.$store.state.User.userID
        TowFlight.creationTime = moment()
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
        TowFlight.creatorID = this.$store.state.User.userID

        if (FlightObject.launchMethod === 2) {
          // VALIADATE TOW FLIGHT
          //  console.log('VALIDATE TOW-FLIGHT')
          var towFlightValidation = await this.$store.dispatch(
            'validateFlight',
            TowFlight
          )
          //  console.log(towFlightValidation)
          if (!this.overwriteWarning) {
            if (towFlightValidation.data.noErrors !== 0 || towFlightValidation.data.noWarnings !== 0) {
              this.$store.commit('setApiErrorResponse', towFlightValidation.data)
              return
            }
          }
          // INSERT TOW FLIGHT
        }
        // VALIDATE MAIN FLIGHT
        //  console.log('VALIDATE MAIN-FLIGHT')
        //  console.log(FlightObject)
        var mainFlightValidation = await this.$store.dispatch(
          'validateFlight',
          FlightObject
        )
        if ((mainFlightValidation.data.noErrors === 0 && mainFlightValidation.data.noWarnings === 0) || this.overwriteWarning) {
          //  console.log('Flights are VALID!')
          if (FlightObject.launchMethod === 2) {
            // INSERT TOW FLIGHT
            this.$store
              .dispatch('insertFlight', TowFlight)
              .then(insertedFlight => {
                //  console.log(insertedFlight.data)
                if (!this.overwriteWarning) {
                  if (insertedFlight.data.noErrors !== 0 || insertedFlight.data.noWarnings !== 0) {
                    this.$store.commit(
                      'setApiErrorResponse',
                      insertedFlight.data
                    )
                    return
                  }
                }
                // REFERENCE TOW FLIGHT IN MAIN FLIGHT
                FlightObject.towFlightID = insertedFlight.data.result
                // INSERT MAIN FLIGHT
                this.$store
                  .dispatch('insertFlight', FlightObject)
                  .then(insertedFlight => {
                    //  (insertedFlight.data)
                    if (!this.overwriteWarning) {
                      if (insertedFlight.data.noErrors !== 0 || insertedFlight.data.noWarnings !== 0) {
                        this.$store.commit(
                          'setApiErrorResponse',
                          insertedFlight.data
                        )
                        return
                      }
                    }
                    this.$store.dispatch('syncFlightTableAndReinitTimer')
                    this.close(true)
                  })
              })
          } else {
            this.$store
              .dispatch('insertFlight', FlightObject)
              .then(insertedFlight => {
                //  console.log(insertedFlight)
                if (!this.overwriteWarning) {
                  if (insertedFlight.data.noErrors !== 0 || insertedFlight.data.noWarnings !== 0) {
                    this.$store.commit(
                      'setApiErrorResponse',
                      insertedFlight.data
                    )
                    return
                  }
                }
                this.$store.dispatch('syncFlightTableAndReinitTimer')
                this.close(true)
              })
          }
        } else {
          this.$store.commit('setApiErrorResponse', mainFlightValidation.data)
        }
      }
      this.disabledWhileLoading = false
      return FlightObject
    },
    async takeoffFlight (flight) {
      //  console.log('TO FLIGHT')
      if (flight.takeoffLocation === null) {
        flight.takeoffLocation = this.$store.state.presets.defaultLocation
      }

      flight.takeoffTime = moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')

      if (flight.flightType === 9) {
        flight.takeoffTime = null
      }
      if (flight.flightType === 10) {
        flight.landingTime = null
      }

      var UpdateValidation = await this.$store.dispatch(
        'validateFlight',
        flight
      )
      //  console.log(UpdateValidation)
      if (UpdateValidation.data.noErrors === 0) {
        this.$store.dispatch('updateFlight', flight).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            this.$store.dispatch('syncFlightTableAndReinitTimer')
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        this.$store.commit('setApiErrorResponse', UpdateValidation.data)
      }
    },
    async landingFlight (flight) {
      //  console.log('Land FLIGHT')
      if (flight.landingLocation === null) {
        flight.landingLocation = this.$store.state.presets.defaultLocation
      }
      flight.landingTime = moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      flight.takeoffTime = moment(flight.takeoffTime)
        .format('YYYY-MM-DD HH:mm:ss')

      if (flight.flightType === 9) {
        flight.takeoffTime = null
      }
      if (flight.flightType === 10) {
        flight.landingTime = null
      }

      flight.landingsLandingLocation = flight.landingsLandingLocation + 1
      var UpdateValidation = await this.$store.dispatch(
        'validateFlight',
        flight
      )
      //  console.log(UpdateValidation)
      if (UpdateValidation.data.noErrors === 0) {
        this.$store.dispatch('updateFlight', flight).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            this.$store.dispatch('syncFlightTableAndReinitTimer')
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        this.$store.commit('setApiErrorResponse', UpdateValidation.data)
      }
    },
    isNumber (n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n)
    },
    planeHasEngine (callsign) {
      if (this.isNumber(callsign[2])) {
        return false
      }
      return true
    },
    overwriteFlightUpdateOrCreateError () {
      this.overwriteWarning = true
      this.save()
    },
    itemRowBackground (item) {
      if (this.tableSchema === 1) {
        // normal (keine class)
      } else if (this.tableSchema === 2) {
        // Unvollständige flüge
        if (
          ([3, 1, 2, 8, 5, 6, 7, 4].includes(item.flightType) && (item.takeoffTime === null || item.landingTime === null)) || // Bei Flügen bei denen start und landezeit erwartet wird
          ([9].includes(item.flightType) && item.landingTime === null) || // Flüge die kommen
          ([10].includes(item.flightType) && item.takeoffTime === null) || // Flüge die Gehen
          ((this.computedPlaneList.filter(p => p.planeID === item.planeID && p.accountingMotortime === 1 && [2, 3, 4, 5].includes(p.type)).length > 0) && (isNaN(item.engineStart) || isNaN(item.engineStop))) // Flüge mit fehlendem Motorzähler
        ) {
          return 'flightMissingInformationColor'
        } else {
          // Normal / Kein Fehler
        }
      } else if (this.tableSchema === 3) {
        // Flugtyp
        switch (item.flightType) {
          case 1:
            return 'flightTypeTrainingColor'
          case 2:
            return 'flightTypeTrainingColor'
          case 3:
            return 'flightTypeNormalColor'
          case 4:
            return 'flightTypeTrainingColor'
          case 5:
            return 'flightTypeMaintenanceColor'
          case 6:
            return 'flightTypeGuestColor'
          case 7:
            return 'flightTypeGuestColor'
          case 8:
            return 'flightTypeTrainingColor'
          case 9:
            return 'flightInboundColor'
          case 10:
            return 'flightOutboundColor'
          default:
            // Normal
            break
        }
      } else if (this.tableSchema === 4) {
        // Eigene/ Fremde Flugzeuge
        if (this.computedPlaneList.filter(p => p.planeID === item.planeID && p.club.toUpperCase().includes('FCBB')).length > 0) {
          return 'flightPlaneClubColor'
        } else {
          return 'flightPlaneExternColor'
        }
      } else if (this.tableSchema === 5) {
        // Startart
        if (item.launchMethod === 1) { // Winde
          return 'flightLaunchmethodWinchColor'
        } else if ([2, 3, 7, 8].includes(item.launchMethod)) { // F-schlepp Glider + Motorplane
          return 'flightLaunchmethodTowColor'
        } else if (item.launchMethod === 6) { // Eigenstart
          return 'flightLaunchmethodSelflaunchColor'
        } else {
          // Normal
        }
      } else if (this.tableSchema === 6) {
        // Localflüge, inbound, outbound
        if (item.takeoffLocation === item.landingLocation) {
          return 'flightLocalColor'
        }
        if (item.takeoffLocation === this.$store.state.presets.defaultLocation) {
          return 'flightOutboundColor'
        }
        if (item.landingLocation === this.$store.state.presets.defaultLocation) {
          return 'flightInboundColor'
        }
      } else {
        // Anders / Fehler = (keine class)
      }
    }
  },
  mounted () {
    // DISABLE RIGHT CLICK
    document.getElementById('flighttable').addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault()
      },
      false
    )
    var that = this
    setInterval(function () {
      // Timer to assign OnlineFlightList to itself, to trigger "computedFlightList"
      var flightListOld = that.$store.state.OnlineFlightList
      that.$store.commit('saveFlightList', [])
      that.$store.commit('saveFlightList', flightListOld)
    }, 60000)
  },
  created () {
    this.$eventBus.$on('openFlightReport', this.openFlightReport)
    this.$eventBus.$on('closeFlightDialog', this.closeFormExtern) // ermöglicht das schließen des FlightDialogs von extern mit löschen des local storage
    this.$eventBus.$on('overwriteFlightDialog', this.overwriteFlightUpdateOrCreateError) // ermöglicht das schließen des FlightDialogs von extern mit löschen des local storage
  }
}
</script>

<template>
<div class="iframe-container">
    <iframe :src="weatherChartUrl" frameborder="0" volume="0"></iframe>
    </div>
</template>
<style>

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%; /* 16:9*/
  margin-top: 10px;
}

.iframe-container iframe {
   height: 100%;
   position: absolute;
   top: 1px;
   width: 100%;
}
</style>
<script>

export default {
  computed: {
    weatherChartUrl: function () {
      if (this.$store.state.Airport.Latitude != null && this.$store.state.Airport.Longitude != null) {
        return 'https://embed.windy.com/embed2.html?lat=' + this.$store.state.Airport.Latitude + '&lon=' + this.$store.state.Airport.Longitude + '&detailLat=' + this.$store.state.Airport.Latitude + '&detailLon=' + this.$store.state.Airport.Longitude + 'zoom=7&level=surface&overlay=radar&product=radar&menu=&message=true&marker=&calendar=12&pressure=true&type=map&location=coordinates&detail=&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1'
      } else {
        return ''
      }
    }
  },
  created () {
  }
}
</script>

<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="font-weight-black" v-if="computedAllFlightsSelected">{{ $store.state.PlaneList.filter(e => e.planeID === this.planeID)[0].callsign }} </v-expansion-panel-header>
    <v-expansion-panel-header class="font-weight-black" v-else>{{ $store.state.PlaneList.filter(e => e.planeID === this.planeID)[0].callsign }} <v-spacer></v-spacer>ACHTUNG - Flüge ausgeblendet<v-spacer></v-spacer> </v-expansion-panel-header>
    <v-expansion-panel-content class="customExpansionPanelContent">
      <v-data-table
    dense
    :headers="LogbookHeaders"
    :items="computedLogbookEntrys"
    no-data-text="Keine Flüge vorhanden..."
    class="elevation-1"
    hide-default-footer
  disable-pagination
  ></v-data-table>
    <v-divider></v-divider>
<v-expansion-panels>
    <v-expansion-panel
    >
      <v-expansion-panel-header>
        Flüge aus-/einbeziehen
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
    v-model="selectedFlights"
    disable-sort
    :headers="headers"
    :items="planeFlights"
    item-key="flightID"
    show-select
    no-data-text="Keine Flüge vorhanden..."
    class="elevation-1"
    hide-default-footer
  disable-pagination
  >
        <template v-slot:item.callsign="{ item }"
        ><span>{{
          item.planeID !== null
            ? $store.state.PlaneList.filter(e => e.planeID == item.planeID)[0]
                .callsign
            : item.callsign
        }}</span></template
      >
      <template v-slot:item.pilotname="{ item }"
        ><span>{{item.pilotID != null ? $store.state.PilotList.filter(e => e.userID == item.pilotID)[0].firstname + " " + $store.state.PilotList.filter(e => e.userID == item.pilotID)[0].lastname : item.pilotName}}</span></template
      >
      <template v-slot:item.copilotname="{ item }"
        ><span>{{
          item.passengerID != null
            ? $store.state.PilotList.filter(
                e => e.userID == item.passengerID
              )[0].firstname +
              " " +
              $store.state.PilotList.filter(
                e => e.userID == item.passengerID
              )[0].lastname
            : item.passengerName
        }}</span></template
      >
      <template v-slot:item.flightType="{ item }"
        ><span>{{
          item.flightType != null ? $store.state.FlightTypeList.filter(e => e.typeID == item.flightType)[0].title : "fail"
        }}</span></template
      >
      <template v-slot:item.launchtype="{ item }"
        ><span>{{
          item.launchMethod != null ? $store.state.LaunchmethodList.filter( e => e.launchID == item.launchMethod)[0].title : "fail"
        }}</span></template
      >
      <template v-slot:item.toLocation="{ item }"
        ><span>{{ item.takeoffLocation }}</span></template
      >
      <template v-slot:item.toTime="{ item }"
        ><span v-if="item.takeoffTime != null"
          >{{ TimestampToTime(item.takeoffTime) }} UTC</span
        ><span v-else> <v-btn disabled>TO</v-btn></span></template
      >
      <template v-slot:item.landTime="{ item }"
        ><span v-if="item.landingTime != null"
          >{{ TimestampToTime(item.landingTime) }} UTC</span
        ><span v-else>
          <v-btn disabled>LAND</v-btn></span
        ></template
      >
      <template v-slot:item.landLocation="{ item }"
        ><span>{{ item.landingLocation }}</span></template
      >
      <template v-slot:item.duration="{ item }"
        ><span v-if="item.takeoffTime != null && item.landingTime != null">{{
          calculateTimeDifference(item.takeoffTime, item.landingTime)
        }}</span
        ><span
          v-else-if="
            item.takeoffTime != null &&
            item.landingTime == null
          "
          >{{ calculateTimeDifference(item.takeoffTime, moment().utc().format('YYYY-MM-DD HH:mm:ss')) }}</span
        ><span v-else>-----</span></template
      >
      <template v-slot:item.payment="{ item }">
        <span>{{ $store.state.PaymentTypeList.filter(e => e.paymenttypeID === item.paymentMethod)[0].title }}</span>
      </template>
  </v-data-table> </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<style>
.customExpansionPanelContent {
  padding: 0 !important;
}
</style>
<script>
import moment from 'moment'
//  import FlightStatisticsVue from '../views/FlightStatistics.vue
export default {
  name: 'LogBook',
  data: () => ({
    selectedFlights: [],
    LogbookHeaders: [ //  Von | Nach | Start erster Flug | Landung letzter Flug | Zahl Landungen | Gesamtflugzeit
      { text: 'Datum', value: 'date' },
      { text: 'Von', value: 'takeoffLocation' },
      { text: 'Nach', value: 'landingLocation' },
      { text: 'erster Start', value: 'takeoffTime' },
      { text: 'letzte Landung', value: 'landingTime' },
      { text: 'Anzahl Landungen', value: 'landings' },
      { text: 'Flugzeit', value: 'flightTime' }
    ],
    headers: [
      { text: 'Callsign', align: 'start', sortable: false, value: 'callsign' },
      { text: 'Pilot', value: 'pilotname' },
      { text: 'Beglieter', value: 'copilotname' },
      { text: 'Flugtyp', value: 'flightType' },
      { text: 'Startart', value: 'launchtype' },
      { text: 'Startort', value: 'toLocation' },
      { text: 'Startzeit', value: 'toTime' },
      { text: 'Landezeit', value: 'landTime' },
      { text: 'Landeaort', value: 'landLocation' },
      { text: 'Dauer', value: 'duration' },
      { text: 'Zahlart', value: 'payment' }
    ]
  }),
  props: {
    planeID: Number,
    planeFlights: Array
  },
  computed: {
    computedLogbookEntrys: function () {
      if (this.selectedFlights.length === 0) {
        return []
      }
      // Copy Selected Flights to avoid "Unexpected side effect in "computedLogbookEntrys" computed property"
      var SelectedFlights = this.selectedFlights
      // Add Sort by takeofftime for safety
      SelectedFlights.sort(this.compareTimestamps)
      var lastTakeoffLocation = SelectedFlights[0].takeoffLocation
      var lastLandingLocation = SelectedFlights[0].landingLocation
      var logbookEntryArray = []
      var lineArray = []
      var priorAirportChange = false

      for (var i = 0; i < SelectedFlights.length; i++) {
        //  console.log(SelectedFlights[i].flightID)
        if (
          (lastTakeoffLocation.toUpperCase() === SelectedFlights[i].takeoffLocation.toUpperCase() && lastLandingLocation.toUpperCase() === SelectedFlights[i].landingLocation.toUpperCase()) ||
          (lastTakeoffLocation.toUpperCase() === lastLandingLocation.toUpperCase() && lastLandingLocation.toUpperCase() === SelectedFlights[i].takeoffLocation.toUpperCase() && !priorAirportChange) ||
          (lastLandingLocation.toUpperCase() === SelectedFlights[i].landingLocation.toUpperCase() && lastLandingLocation.toUpperCase() === SelectedFlights[i].takeoffLocation.toUpperCase() && !priorAirportChange) ||
          (SelectedFlights[i].flightType === 9) ||
          (SelectedFlights[i].flightType === 10)
        ) {
          if (
            (lastTakeoffLocation.toUpperCase() === lastLandingLocation.toUpperCase() && lastLandingLocation.toUpperCase() === SelectedFlights[i].takeoffLocation.toUpperCase() && SelectedFlights[i].takeoffLocation.toUpperCase() !== SelectedFlights[i].landingLocation.toUpperCase()) ||
            (lastLandingLocation.toUpperCase() === SelectedFlights[i].landingLocation.toUpperCase() && lastLandingLocation.toUpperCase() === SelectedFlights[i].takeoffLocation.toUpperCase() && lastTakeoffLocation.toUpperCase() !== lastLandingLocation.toUpperCase())) {
            priorAirportChange = true
            //  console.log('prior airport change set TRUE')
          }
          lineArray.push(SelectedFlights[i])
          //  console.log('1')
        } else {
          //  console.log('2')
          logbookEntryArray.push(lineArray)
          priorAirportChange = false
          lineArray = []
          lineArray.push(SelectedFlights[i])
        }

        if (i === SelectedFlights.length - 1) {
          logbookEntryArray.push(lineArray)
        }

        lastTakeoffLocation = SelectedFlights[i].takeoffLocation
        lastLandingLocation = SelectedFlights[i].landingLocation
      }

      return this.convertListOfFlightsToLogbookEntry(logbookEntryArray)
    },
    computedAllFlightsSelected: function () {
      return this.selectedFlights.length === this.planeFlights.length
    }
  },
  methods: {
    // Custom comparison function to compare timestamps
    compareTimestamps (a, b) {
      const timestampA = new Date(a.takeoffTime.replace(' ', 'T')) // Convert to Date object
      const timestampB = new Date(b.takeoffTime.replace(' ', 'T')) // Convert to Date object
      return timestampA - timestampB
    },
    TimestampToTime: function (timestamp) {
      return moment(timestamp).format('HH:mm')
    },
    calculateTimeDifference: function (takeoff, landing) {
      var ms = moment(landing).diff(moment(takeoff))
      var d = moment.duration(ms)
      return Math.floor(d.asHours()) + moment.utc(ms).format(':mm')
    },
    getTimeFromMins: function (mins) {
    // do not include the first validation check if you want, for example,
    // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
      if (mins >= 24 * 60 || mins < 0) {
        throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.')
      }
      var h = mins / 60 | 0
      var m = mins % 60 | 0
      return moment.utc().hours(h).minutes(m).format('HH:mm')
    },
    convertListOfFlightsToLogbookEntry (flightListArray) {
      var returnArray = []
      for (var x = 0; x < flightListArray.length; x++) {
        var flightList = flightListArray[x]
        var numberOfLandings = 0
        var thisLineFlightTime = 0

        for (var i = 0; i < flightList.length; i++) {
          thisLineFlightTime += moment(flightList[i].landingTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(flightList[i].takeoffTime, 'YYYY-MM-DD HH:mm:ss'), 'minutes')
          numberOfLandings += flightList[i].landingsTakeoffLocation + flightList[i].landingsLandingLocation
        }
        //  Von | Nach | Start erster Flug | Landung letzter Flug | Zahl Landungen | Gesamtflugzeit
        returnArray.push({
          date: moment(flightList[0].takeoffTime).utc().format('DD.MM.YYYY'),
          takeoffLocation: flightList[0].takeoffLocation,
          landingLocation: flightList[flightList.length - 1].landingLocation,
          takeoffTime: this.TimestampToTime(flightList[0].takeoffTime),
          landingTime: this.TimestampToTime(flightList[flightList.length - 1].landingTime),
          landings: numberOfLandings,
          flightTime: this.getTimeFromMins(thisLineFlightTime)
        })
      }
      return returnArray
    }
  },
  mounted () {
    this.selectedFlights = this.planeFlights
  }
}
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="Pilots"
    :sort-by="['lastname', 'firstname']"
    :search="search"
    no-data-text="Keine Piloten vorhanden..."
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Piloten</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" dark class="mb-2" v-bind="attrs" v-on="on" :disabled="!canChangeUsers">
              Neuer Pilot
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.firstname"
                      :rules="rules.standardRequiredRule"
                      label="Vorname"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.lastname"
                      :rules="rules.standardRequiredRule"
                      label="Nachname"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.userName"

                      :disabled="editedIndex != -1"
                      label="Username"
                      required
                    ></v-text-field>
                  </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete

                      :items="[{short: 'M', long: 'Male'},{short: 'F', long: 'Female'}]"
                        v-model="editedItem.gender"
                        item-text="long"
                        item-value="short"
                        label="Gender"
                        required></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" >
<v-menu

      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      :disabled="editedIndex != -1"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="editedItem.birthday"
          :disabled="editedIndex != -1"
          label="Geburtstag"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="editedItem.birthday"
        :disabled="editedIndex != -1"
        :active-picker.sync="activePicker"
        :max="new Date().toISOString().substr(0, 10)"
        min="1950-01-01"
        @change="saveBirthDate"
      ></v-date-picker>
    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" >
                    <v-text-field
                      v-model="editedItem.mail"
                      :rules="rules.emailRules"

                      label="E-Mail"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.tel"
                      type="text"
                      :rules="rules.phoneNumberRequiredRule"
                      label="Tel"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.tel2"
                      type="text"
                      :rules="rules.standardRequiredRule"
                      label="Tel 2"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.country"

                      :rules="rules.standardRequiredRule"
                      label="Land"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="7" sm="7" md="7">
                    <v-text-field
                      v-model="editedItem.town"

                      :rules="rules.standardRequiredRule"
                      label="Stadt"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" sm="5" md="5">
                    <v-text-field
                      v-model="editedItem.zip"

                      :rules="rules.standardIntegerRequiredRule"
                      type="number"
                      label="PLZ"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" sm="10" md="10">
                    <v-text-field
                      v-model="editedItem.street"
                      :rules="rules.standardRequiredRule"
                      label="Straße"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-text-field
                      v-model="editedItem.houseNo"

                      :rules="rules.standardIntegerRequiredRule"
                      label="Nr."
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.club"
                      :rules="rules.standardRequiredRule"
                      :disabled="editedIndex != -1"
                      label="Club"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete

                       :items="$store.state.MenbershipTypeList"
                        v-model="editedItem.membership"
                        item-text="title"
                        item-value="typeID"
                        label="Mitglied"
                        required></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                        v-model="editedItem.position"
                        label="Position"
                        ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn color="red darken-1" text @click="close">
                Abbrechen
              </v-btn>
                            <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="save">
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
       <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)" v-if="item.userID === $store.state.User.userID || canChangeUsers">
        mdi-pencil
      </v-icon>
      <p v-else>
      keine Aktionen verfügbar
      </p>
    </template>
  </v-data-table>
</template>
<script>
import moment from 'moment'
export default {
  data: () => ({
    dialog: false,
    menu: false,
    canChangeUsers: false,
    activePicker: null,
    search: '',
    rules: {
      standardRequiredRule: [
        value => !!value || 'Muss angegeben werden!',
        value => value.length < 32 || 'Maximal 32 Zeichen erlaubt'
      ],
      standardIntegerRequiredRule: [
        value => !!value || 'Muss angegeben werden!'
      ],
      phoneNumberRequiredRule: [
        value => !!value || 'Telefonnummer muss angegeben werden!',
        value => value.length > 11 || 'Mindestens 12 Zahlen benötigt'
      ],
      emailRules: [
        v => !!v || 'E-Mail muss angegeben werden',
        v => /.+@.+\..+/.test(v) || 'Korrekte E-Mail angeben!'
      ]
    },
    headers: [
      { text: 'ID', value: 'userID' },
      {
        text: 'Vorname',
        align: 'start',
        value: 'firstname'
      },
      { text: 'Nachname', value: 'lastname' },
      { text: 'Aktionen', value: 'actions', sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      userName: '',
      firstname: '',
      lastname: '',
      mail: '',
      tel: '',
      tel2: '',
      street: '',
      membership: '',
      houseNo: '',
      zip: '',
      town: '',
      country: '',
      club: '',
      gender: '',
      birthday: '',
      position: ''
    },
    defaultItem: {
      userName: '',
      firstname: '',
      lastname: '',
      membership: '',
      mail: '',
      tel: '',
      tel2: '',
      street: '',
      houseNo: '',
      zip: '',
      town: '',
      country: '',
      club: '',
      gender: '',
      birthday: '',
      position: ''
    }
  }),
  computed: {
    computedUserName () {
      return this.editedItem.firstname[0] + this.editedItem.lastname
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Neuer Pilot' : 'Pilot bearbeiten'
    },
    Pilots () {
      return this.$store.state.PilotList
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    generatestandardPassword () {
      return this.editedItem.firstname.toLowerCase() + this.editedItem.lastname + '.' + moment(this.editedItem.birthday).format('YYYY')
    },
    editItem (item) {
      this.editedIndex = this.$store.state.PilotList.indexOf(item)
      this.$store.dispatch('getFullUserInfo', item.userID).then(response => {
        var data = response.data.result
        //  console.log(data)
        for (var [key, value] of Object.entries(data)) {
          if (!isNaN(value) && value !== null) {
            data[key] = parseInt(value)
          }
        }
        this.editedItem = data
      })
      this.dialog = true
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    saveBirthDate (date) {
      this.$refs.menu.save(date)
    },
    save () {
      if (this.editedIndex > -1) {
        // validate USER
        // Edit User
        this.$store.dispatch('validateUser', this.editedItem).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            // Update USER
            this.$store.dispatch('updateUser', this.editedItem).then(response => {
              if (parseInt(response.data.noErrors) === 0) {
                this.$store.dispatch('loadPilotList')
                this.close()
              } else {
                this.$store.commit('setApiErrorResponse', response.data)
              }
            })
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        // Inserting User
        // validate User
        this.editedItem.password = this.generatestandardPassword()
        this.$store.dispatch('validateUser', this.editedItem).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            //  console.log('USER VALID.....INSERTING')
            // Insert Plane
            this.$store.dispatch('insertUser', this.editedItem).then(response => {
              console.log(response)
              if (parseInt(response.data.noErrors) === 0) {
                this.$store.dispatch('loadPilotList')
                this.close()
              } else {
                this.$store.commit('setApiErrorResponse', response.data)
              }
            })
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      }
    }
  },
  async mounted () {
    this.canChangeUsers = await this.$store.dispatch('checkUserCanChangeUser')
  }
}
</script>

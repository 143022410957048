<template>
  <div>
  <v-container fluid  v-if="computedPlaneList.length > 0">
    <v-row no-gutters>
      <v-col md="4" sm="12" cols="12">
        <v-card elevation="2">
          <v-card-title>Startarten</v-card-title>
          <v-card-subtitle>
            NUR GELANDETE FLÜGE EINBEZOGEN
          </v-card-subtitle>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Startart
                    </th>
                    <th class="text-left">
                      Anzahl
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in computedLaunchMethodStatistic"
                    :key="item.method"
                  >
                    <td>
                      {{
                        $store.state.LaunchmethodList.filter(
                          e => e.launchID === item.method
                        )[0].title
                      }}
                    </td>
                    <td>{{ item.count }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="4" sm="12" cols="12">
        <v-card elevation="2"
          ><v-card-title>Flugzeuganteile</v-card-title>
          <v-card-text
            ><vue-chart
              type="doughnut"
              :data="computedPlaneUsageStatistic"
            ></vue-chart></v-card-text
        ></v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <h3>Bordbucheinträge</h3>
      <v-expansion-panels accordion>
        <Logbook
          v-for="planeID in computedPlaneList"
          :key="planeID"
          :planeID="planeID"
          :planeFlights="getFlightsForPlane(planeID)"
        ></Logbook>
      </v-expansion-panels>
    </v-row>
  </v-container>
  <v-container v-else fluid>
      <span class="justify-center d-flex display-1">Warte auf abgeschlossene Flüge...</span>
</v-container>
  </div>
</template>

<script>
import Logbook from '../components/Logbook.vue'
import moment from 'moment'
import VueChart from 'vue-chart-js'

export default {
  name: 'FlightStatistics',
  components: {
    Logbook,
    VueChart
  },
  computed: {
    computedPlaneList: function () {
      var flags = []
      var output = []
      var l = this.$store.state.StatisticsFlightList.length
      var i
      for (i = 0; i < l; i++) {
        if (flags[this.$store.state.StatisticsFlightList[i].planeID] || this.$store.state.StatisticsFlightList[i].planeID === null) continue
        flags[this.$store.state.StatisticsFlightList[i].planeID] = true
        output.push(this.$store.state.StatisticsFlightList[i].planeID)
      }
      return output
    },
    computedLaunchMethodStatistic: function () {
      var list = []
      var flags = []
      var output = []
      var l = this.$store.state.StatisticsFlightList.length
      var i
      for (i = 0; i < l; i++) {
        if (flags[this.$store.state.StatisticsFlightList[i].launchMethod]) { continue }
        flags[this.$store.state.StatisticsFlightList[i].launchMethod] = true
        output.push(this.$store.state.StatisticsFlightList[i].launchMethod)
      }
      for (var x = 0; x < output.length; x++) {
        list.push({
          method: output[x],
          count: this.$store.state.StatisticsFlightList.filter(
            e => e.launchMethod === output[x]
          ).length
        })
      }
      return list
    },
    computedPlaneUsageStatistic: function () {
      var chartLabels = []
      var chartData = []

      var flags = []
      var output = []
      var l = this.$store.state.StatisticsFlightList.length
      var i
      for (i = 0; i < l; i++) {
        if (flags[this.$store.state.StatisticsFlightList[i].planeID]) continue
        flags[this.$store.state.StatisticsFlightList[i].planeID] = true
        output.push(this.$store.state.StatisticsFlightList[i].planeID)
      }
      for (var x = 0; x < output.length; x++) {
        if (output[x] === null) continue

        chartLabels.push(
          this.$store.state.PlaneList.filter(e => e.planeID === output[x])[0]
            .callsign
        )
        chartData.push(
          this.$store.state.StatisticsFlightList.filter(
            e => e.planeID === output[x]
          ).length
        )
      }

      return {
        labels: chartLabels,
        datasets: [
          {
            label: 'Dataset 1',
            data: chartData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }
        ]
      }
    }
  },
  methods: {
    random_rgba: function () {
      var o = Math.round
      var r = Math.random
      var s = 255
      return (
        'rgba(' +
        o(r() * s) +
        ',' +
        o(r() * s) +
        ',' +
        o(r() * s) +
        ',' +
        r().toFixed(1) +
        ')'
      )
    },
    getTimeFromMins: function (mins) {
      // do not include the first validation check if you want, for example,
      // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
      if (mins >= 24 * 60 || mins < 0) {
        throw new RangeError(
          'Valid input should be greater than or equal to 0 and less than 1440.'
        )
      }
      var h = (mins / 60) | 0
      var m = mins % 60 | 0
      return moment
        .utc()
        .hours(h)
        .minutes(m)
        .format('HH:mm')
    },
    getFlightsForPlane: function (planeID) {
      return this.$store.state.StatisticsFlightList.filter(
        e => e.planeID === planeID
      )
    }
  },
  mounted () {
    this.$store.dispatch('loadStatisticsFlightList')
  }
}
</script>


<template>
  <v-row justify="space-around" v-if="dialog">
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialog"
        persistent
      >
          <v-card>
            <v-toolbar
              :color="dialogOptions.color"
              dark
            >{{ dialogOptions.title }} <v-spacer></v-spacer> {{dialogOptions.counter}} {{ dialogOptions.type }}</v-toolbar>
            <v-card-text style="white-space: pre; color: black; font-size: 1.2em;">
              <span>{{ dialogOptions.message }}</span>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                v-if="dialogOptions.type === 'Warnungen'"
                text
                color="red"
                @click="continueDialog"
              >Überschreiben</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                :color="dialogOptions.color"
                @click="closeDialog"
              >Verstanden</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
  }),
  methods: {
    loadError: function () {
      this.errorData = this.$store.state.ApiErrorResponse
      this.dialog = true
    },
    closeDialog () {
      this.$store.commit('deleteApiErrorResponse')
    },
    continueDialog () {
      if (this.dialogOptions.type === 'Warnungen') {
        this.$eventBus.$emit('overwriteFlightDialog')
      }
      this.$store.commit('deleteApiErrorResponse')
    }
  },
  computed: {
    dialog () {
      if (this.$store.state.ApiErrorResponse.noErrors > 0 || this.$store.state.ApiErrorResponse.noWarnings > 0) {
        return true
      }
      return false
    },
    DialogObject () {
      return this.$store.state.ApiErrorResponse
    },
    dialogOptions () {
      if (this.$store.state.ApiErrorResponse.noErrors > 0) {
        return { color: 'red', title: 'Fehler!', type: 'Fehler', message: this.DialogObject.errorMsg, counter: this.DialogObject.noErrors }
      } else if (this.$store.state.ApiErrorResponse.noWarnings > 0) {
        return { color: 'amber darken-2', title: 'Warnung!', type: 'Warnungen', message: this.DialogObject.warningMessage, counter: this.DialogObject.noWarnings }
      }
      return { color: 'blue', title: 'Bitte Admin Kontaktieren', type: 'Systemfehler' }
    },
    error () {
      return this.$store.state.ApiErrorResponse
    }
  }
}
</script>

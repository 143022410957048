import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import AxiosPlugin from 'vue-axios-cors'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import IdleVue from 'idle-vue'

Vue.prototype.$eventBus = new Vue() // Global event bus

Vue.config.productionTip = false

// Abort open Requests Global after 2.5s
axios.defaults.timeout = 2500

Vue.use(VueCookies)
if (process.env.VUE_APP_ENV === 'production') {
  Vue.$cookies.config('8h', '/', '.fcbb.de') // Set cookie expire global SSO CONF
} else {
  Vue.$cookies.config('8h') // Set cookie expire global DEV CONF
}
Vue.use(AxiosPlugin)
Vue.use(DatetimePicker)
const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 4 * 60 * 60 * 1000 // 4 Stunden
})

new Vue({
  store,
  router,
  vuetify,
  beforeCreate () {
    this.$store.commit('initialiseStore')
    this.$store.dispatch('initialiseAction')
  },
  render: h => h(App)
}).$mount('#app')

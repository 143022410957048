<template>
<v-container fluid>
  <UserTable/>
</v-container>
</template>

<script>
import UserTable from '../components/UserList.vue'
export default {
  name: 'Pilots',
  components: {
    UserTable
  }
}
</script>

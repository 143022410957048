<template>
  <v-row class="fill-height">
    <v-dialog
          v-model="dialog"
          v-if="editEvent"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ dialogUpdate ? 'Buchung bearbeiten' : 'Neue Buchung' }}</span>
              <v-spacer></v-spacer>
              <v-btn  v-if="dialogUpdate" text color="error" @click="removeBooking">Löschen</v-btn>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
          <v-col cols="12">
          <v-select
            v-model="editEvent.planeID"
            :items="planesForBooking"
            item-text="callsign"
            item-value="planeID"
            label="Flugzeug"
            required
          ></v-select>
        </v-col>

        <v-col cols="12">
          <input
            style="margin-top: 20px"
            v-model="editEvent.start"
            type="datetime-local"
          />
        </v-col>
        <v-col cols="12">
          <input
            style="margin-top: 20px"
            v-model="editEvent.end"
            type="datetime-local"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editEvent.comment"
            label="Kommentar"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
                                    <v-autocomplete
                            v-model="editEvent.userID"
                            :items="computedPilotListAll"
                            item-value="userID"
                            item-text="fullname"
                            label="Buchung für (Standard du selbst!)"
                          >
                          </v-autocomplete>
        </v-col>
                  <v-col cols="12">
          <v-select
            v-model="editEvent.type"
            :items="bookingTypes"
            item-text="bookingTypeName"
            item-value="bookingTypeID"
            label="Buchungstyp"
            required
          ></v-select>
        </v-col>
      </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="dialog = false; resetDialog(); cancelDrag(); loadEvents(); dialogUpdate = false"
              >
                Abbrechen
                   </v-btn>
                                 <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                v-if="dialogUpdate"
                :disabled="editEvent.planeID === -1"
                @click="updateBooking"
              >
                Speichern
              </v-btn>
                <v-btn
                color="green darken-1"
                text
                v-else
                :disabled="editEvent.planeID === -1"
                @click="saveBooking"
              >
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>
    <v-col cols="12">
        <v-row style="padding-top: 8px;">
          <v-col cols="12" sm="5" md="4">
            <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Heute
          </v-btn>
        <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Tag</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Woche</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Monat</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 Tage</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          </v-col>
          <v-col cols="6" sm="3">
            <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          </v-col>
        </v-row>
      <v-sheet height="820">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          event-overlap-mode="column"
          :events="events"
          :type="type"
          locale="de"
          weekdays="1,2,3,4,5,6,0"
          @click:event="openDragEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="loadEvents"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
        >
        <template v-slot:event="{ event, timed }">
            <div style="margin-left: 8px; margin-right: 5px;">
              <strong>{{event.callsign}}&nbsp;-&nbsp;{{event.name}} &nbsp; | {{ getHHmm(event.start) }} - {{ getHHmm(event.end) }} </strong>

            <p v-if="timed && event.canEdit">
            <v-icon color="white" small>mdi-pencil</v-icon>
            Zum bearbeiten / löschen klicken.
            </p>
            <p v-if="event.type === 1"><u>GROUNDING</u></p>
            <p>
              {{event.comment}}
            </p>
            </div>
            <div
              v-if="timed && event.canEdit"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
            ></div>
          </template>
          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>
      </v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
<script>
import moment from 'moment'
export default {
  data: () => ({
    focus: '',
    type: '4day',
    typeToLabel: {
      month: 'Monat',
      week: 'Woche',
      day: 'Tag',
      '4day': '4 Tage'
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    value: '',
    ready: false,
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    dragTime: null,
    extendOriginal: null,
    dialog: false,
    dialogUpdate: false,
    initialLoad: true,
    bookingTypes: [
      {
        bookingTypeID: 0,
        bookingTypeName: 'Reservierung'
      },
      {
        bookingTypeID: 1,
        bookingTypeName: 'Grounding'
      }
    ],
    editEvent: {
      planeID: -1,
      userID: null,
      type: 0,
      start: '',
      end: '',
      comment: ''
    },
    defaultEvent: {
      planeID: -1,
      userID: null,
      type: 0,
      start: '',
      end: '',
      comment: ''
    }
  }),
  mounted () {
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  computed: {
    planesForBooking: function () {
      return this.$store.state.PlaneList.filter(e => e.booking === 1)
    },
    computedPilotListAll () {
      return this.$store.state.PilotList
    },
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    }
  },
  methods: {
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        // eslint-disable-next-line no-return-assign
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    saveBooking () {
      if (this.editEvent.userID === null) {
        this.editEvent.userID = this.$store.state.User.userID
      }
      this.editEvent.start = moment(this.editEvent.start).format('YYYY-MM-DD HH:mm:ss')
      this.editEvent.end = moment(this.editEvent.end).format('YYYY-MM-DD HH:mm:ss')
      this.$store.dispatch('insertBooking', this.editEvent).then(response => {
        //  console.log(response)
        if (parseInt(response.data.noErrors) === 0) {
          // Insert Bookings
          this.calendarPlaneFilter = this.editEvent.planeID
          this.dialog = false
          this.resetDialog()
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
        this.loadEvents()
      })
    },
    removeBooking () {
      if (confirm('Buchung löschen?')) {
        this.$store.dispatch('deleteBooking', this.editEvent).then(response => {
          //  console.log(response)
          if (parseInt(response.data.noErrors) === 0) {
          // delete Booking
            this.dialog = false
            this.dialogUpdate = false
            this.resetDialog()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
          this.loadEvents()
        })
      }
    },
    updateBooking () {
      console.log('update booking')
      this.editEvent.start = moment(this.editEvent.start).format('YYYY-MM-DD HH:mm:ss')
      this.editEvent.end = moment(this.editEvent.end).format('YYYY-MM-DD HH:mm:ss')
      this.$store.dispatch('updateBooking', this.editEvent).then(response => {
        //  console.log(response)
        if (parseInt(response.data.noErrors) === 0) {
          // Insert Bookings
          this.dialog = false
          this.dialogUpdate = false
          this.resetDialog()
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
        this.loadEvents()
      })
    },
    getHHmm (time) {
      return moment(time).format('HH:mm')
    },
    checkIfEventIsTimed (start, end) {
      if (moment(start).format('MM') === moment(end).format('MM')) {
        return (moment(start).format('DD') === moment(end).format('DD'))
      }
      return false
    },
    loadEvents: async function () {
      await this.$store.dispatch('loadBookings')
      await this.$store.dispatch('loadPilotList')
      await this.$store.dispatch('loadPlaneList')
      if (this.initialLoad) {
        this.calendarPlaneFilter = this.planesForBooking[0].planeID
        //  this.scrollToTime()
        this.initialLoad = false
      }
      var events = []
      this.$store.state.Bookings.forEach(item => {
        item.name = this.$store.state.PilotList.filter(e => e.userID === item.userID)[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === item.userID)[0].lastname
        item.callsign = this.$store.state.PlaneList.filter(e => e.planeID === item.planeID)[0].callsign
        item.category = this.$store.state.PlaneList.filter(e => e.planeID === item.planeID)[0].callsign
        item.start = moment(item.start).valueOf()
        item.end = moment(item.end).valueOf()
        item.timed = this.checkIfEventIsTimed(item.start, item.end) // Need to be set to verify that its not all the day long
        events.push(item)
      })
      this.events = events
    },
    startDrag ({ event, timed }) {
      console.log('start drag')
      if (event && timed) {
        console.log('start drag -- 1')
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime (tms) {
      console.log('start time')
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime === null) {
        console.log('start time 1')
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      } else {
        console.log('start time 2')
        this.createStart = this.roundTime(mouse)
        this.createEvent = {
          name: 'Neue Buchung',
          color: 'grey',
          start: this.createStart,
          end: this.createStart,
          planeID: this.calendarPlaneFilter,
          timed: true
        }

        this.events.push(this.createEvent)
      }
    },
    extendBottom (event) {
      console.log('extend bottom')
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove (tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        console.log('mouse 1')
        if ((this.dragEvent !== null && this.dragEvent.canEdit === 1) || (this.createEvent !== null && this.createEvent.canEdit === 1)) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
        }
      } else if (this.createEvent && this.createStart !== null) {
        console.log('mouse 2')
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    openDragEvent ({ nativeEvent, event }) {
      console.log('openDragEvent')
      this.editEvent.start = moment(event.start).format('YYYY-MM-DDTHH:mm:ss')
      this.editEvent.end = moment(event.end).format('YYYY-MM-DDTHH:mm:ss')
      this.editEvent.planeID = event.planeID
      this.editEvent.comment = event.comment
      this.editEvent.bookingID = event.bookingID
      this.editEvent.userID = event.userID
      this.editEvent.type = event.type
      this.dialogUpdate = true
      this.dialog = true
    },
    endDrag () {
      console.log('endDrag')
      if (this.dragEvent && this.dragTime !== null) {
        // Complete drag
        // if ((this.dragEvent !== null && this.dragEvent.canEdit === 1) || (this.createEvent !== null && this.createEvent.canEdit === 1)) {
        //   console.log('endDrag 1')
        //   this.openDragEvent(this.dragEvent)
        // }
      } else if (this.createEvent && this.extendOriginal !== null) {
        // extend drag
        console.log('endDrag 2')
        this.editEvent.start = moment(this.createEvent.start).format('YYYY-MM-DDTHH:mm:ss')
        this.editEvent.end = moment(this.createEvent.end).format('YYYY-MM-DDTHH:mm:ss')
        this.editEvent.planeID = this.createEvent.planeID
        this.editEvent.comment = this.createEvent.comment
        this.editEvent.bookingID = this.createEvent.bookingID
        this.editEvent.userID = this.createEvent.userID
        this.editEvent.type = this.dragEvent.type
        this.dialogUpdate = true
        this.dialog = true
      } else {
        // new Drag
        console.log('endDrag 3')
        this.editEvent.planeID = this.createEvent.planeID
        this.editEvent.start = moment(this.createEvent.start).format('YYYY-MM-DDTHH:mm:ss')
        this.editEvent.end = moment(this.createEvent.end).format('YYYY-MM-DDTHH:mm:ss')
        this.dialog = true
      }

      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag () {
      console.log('cancelDrag')
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    resetDialog () {
      this.editEvent = Object.assign({}, this.defaultEvent)
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    roundTime (time, down = true) {
      const roundTo = 5 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    }
  }
}
</script>

<template>
  <div>
    <div dark class="bar hidden-sm-and-down"
      v-if="computedLastHeartbeat.response_status_text != 'OK' && computedLastHeartbeat.response_status_code == 200">
      <span class="bar_content" v-html="computedLastHeartbeat.response_status_text">
      </span>
    </div>
    <v-container style="padding: 8px" fluid>
      <v-row no-gutter>
        <v-col md="8" sm="12" cols="12">
          <v-row no-gutter>
            <v-col md="6" sm="6" cols="12">
            <v-container>
              <v-row class="font-weight-bold">Zeit UTC: {{ formatTimestamp(UtcTimestamp) }}</v-row>
              <v-row>Zeit (local): {{ formatTimestamp(LocalTimestamp) }}</v-row>
              <v-row>Flüge in der Liste:
                {{ this.$store.state.OnlineFlightList.length }}</v-row>
              <v-row>Flüge in der Luft:&nbsp;<strong :style="flightsInAir != 0 ? 'color: red' : ''"> {{
                  flightsInAir
              }}</strong>
              </v-row>
              <v-row>Abgeschlossene Flüge:
                {{
                    this.$store.state.OnlineFlightList.filter(
                      e => e.takeoffTime !== null && e.landingTime !== null
                    ).length
                }}</v-row>
              <v-row v-if="flightLogDate != currentDate" style="color: red"><strong>Anzeigedatum:
                  {{
                      formatDate(flightLogDate)
                  }}</strong></v-row>
            </v-container>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <v-container>
              <v-row>Sonnenaufgang:
                {{
                    convert12HrTimeTo24Hr(
                      this.$store.state.TimeInformations.civil_twilight_begin
                    )
                }}
                UTC</v-row>
              <v-row>Sonnenuntergang:
                {{
                    convert12HrTimeTo24Hr(
                      this.$store.state.TimeInformations.civil_twilight_end
                    )
                }}
                UTC</v-row>
              <v-row></v-row>
              <v-row>Metar:</v-row>
              <v-row>{{ this.$store.state.Airport.Metar }}</v-row>
            </v-container>
          </v-col>
          </v-row>
        </v-col>
        <v-col lg="4" sm="12" cols="12">
          <HeartBeat />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style>
* {
  box-sizing: border-box;
}

.bar {
  top: 0;
  left: 0;

  width: 100%;
  font-size: 18px;
}

.bar_content {
  display: block;
  /* Important to give the content a width */

  width: 100%;
  transform: translateX(100%);
  /* Animation start out of the screen */

  /* Add the animation */
  animation: move 30s linear infinite
    /* infinite make reapeat the animation indefinitely */
  ;
}

/* Create the animation */
@keyframes move {
  to {
    transform: translateX(-100%);
  }
}
</style>
<script>
import moment from 'moment'
import HeartBeat from './HeartbeatCard.vue'
export default {
  name: 'InformationCard',
  data: () => ({
    UtcTimestamp: '',
    LocalTimestamp: '',
    Airport: {
      Metar: '',
      Latitude: '',
      Longitude: '',
      TimeInformations: {}
    }
  }),
  components: {
    HeartBeat
  },
  methods: {
    formatDateTime (timestamp) {
      return moment(timestamp).format('HH:mm:ss')
    },
    getUtcTime: function () {
      var tmp = new Date()
      this.UtcTimestamp = tmp.getTime() + tmp.getTimezoneOffset() * 60000
    },
    getLocalTime: function () {
      this.LocalTimestamp = new Date().getTime()
    },
    formatTimestamp: function (unixTimestamp) {
      return moment(unixTimestamp).format('DD.MM.YYYY HH:mm:ss')
    },
    formatDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    convert12HrTimeTo24Hr (time) {
      return moment(time, 'hh:mm:ss A').format('HH:mm')
    }

  },
  computed: {
    computedLastHeartbeat () {
      return this.$store.state.Heartbeat
    },
    flightsInAir () {
      return this.$store.state.OnlineFlightList.filter(
        e => {
          return (e.takeoffTime !== null && e.landingTime === null && e.flightType !== 10) ||
            (e.flightType === 10 && e.takeoffTime !== null && moment(this.UtcTimestamp).diff(moment(e.takeoffTime), 'minutes') < 6) ||
            (e.flightType === 9 && e.landingTime === null)
        }
      ).length
    },
    currentDate () {
      return moment().format('YYYY-MM-DD')
    },
    flightLogDate () {
      return this.$store.state.FlightLogDate
    }
  },
  created () {
    setInterval(() => {
      this.getLocalTime()
      this.getUtcTime()
    }, 500)

    setInterval(() => {
      this.$store.dispatch('updateMetar')
    }, 600000)
  }
}
</script>

<template>
 <v-navigation-drawer clipped app  v-model="drawer" :width="180">
      <v-list>
        <v-list-item v-for="(item, index) in navItems" :key="index" :to="{ name: item.routerName}">
         <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="flightReport">
         <v-list-item-icon>
            <v-icon>fa-exclamation-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Schaden melden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="(item, index) in externalItems" :key="index + navItems.length" target="_blank" :href="item.href">
         <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
  data: () => ({
    drawer: true,
    navItems: [
      { title: 'FlightLog', routerName: 'flightlog', icon: 'fa-plane-departure' },
      { title: 'Anwesenheit', routerName: 'attendance', icon: 'fa-list' },
      { title: 'Statistik', routerName: 'statistics', icon: 'fa-chart-bar' },
      { title: 'Wetter', routerName: 'weather', icon: 'fa-globe-europe' },
      { title: 'Buchung', routerName: 'booking', icon: 'mdi-calendar' },
      { title: 'Gutscheine', routerName: 'coupon', icon: 'mdi-wallet-giftcard' },
      { title: 'Piloten', routerName: 'pilotlist', icon: 'fa-users' },
      { title: 'Flugzeuge', routerName: 'planelist', icon: 'fa-warehouse' },
      { title: 'Info', routerName: 'about', icon: 'mdi-information-outline' }
    ],
    externalItems: [
      { title: 'MyAccount', href: 'https://myaccount.fcbb.de', icon: 'mdi-card-account-details-star-outline' }
    ]
  }),
  computed: {
  },
  methods: {
    async flightReport () {
      if (this.$route.name !== 'flightlog') {
        this.$router.push({ name: 'flightlog' })
        await new Promise(resolve => setTimeout(resolve, 500))
      }
      this.$eventBus.$emit('openFlightReport')
    }
  },
  mounted () {
    this.$root.$on('toggleNavBarButton', data => {
      console.log(data)
      this.drawer = !this.drawer
    })
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import axios from 'axios'
var qs = require('qs')

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Initialisation: false,
    RoamingMode: false,
    FliegerlagerMode: false,
    FlightLogDate: null,
    Intervals: {
      HeartbeatInterval: 2500,
      Heartbeat: null,
      FlightReloadInterval: 3000,
      FlightReload: null
    },
    GlobalFlightListFilterButtons: {
      showOnlyMyFlights: false,
      hideFinishedFlights: false
    },
    MetarICAO: 'EDDE', // FIRST PRESET
    presets: {
      defaultLocation: 'EDOB' // FIRST PRESET
    },
    ApiErrorResponse: [],
    Heartbeat: {},
    HeartbeatResponseStatistic: [],
    TimeInformations: {},
    AttendanceList: [],
    PilotList: [],
    PlaneList: [],
    OfflineFlightList: [],
    OnlineFlightList: [],
    StatisticsFlightList: [],
    Bookings: [],
    Airport: {
      Metar: null,
      Latitude: null,
      Longitude: null
    },
    AirportList: [],
    CouponPaymentMethodCoupons: [
      { title: 'Bar in Handkasse', pID: 1 },
      { title: 'Belastung meines Fliegerkontos', pID: 2 },
      { title: 'Überweisung auf Vereinskonto', pID: 3 },
      { title: 'Sonstiges nach Absprache', pID: 4 }
    ],
    LaunchmethodList: [
      { title: 'Winde', launchID: 1 },
      { title: 'F-Schlepp', launchID: 2 },
      { title: 'Eigenstart', launchID: 6 },
      { title: 'Schleppflugzeug im Schleppzug', launchID: 3 },
      { title: 'Gummiseil', launchID: 4 },
      { title: 'Autoschlepp', launchID: 5 },
      { title: 'F-Schlepp extern (Segler)', launchID: 7 },
      { title: 'F-Schlepp extern (Schleppmaschine)', launchID: 8 }
    ],
    FlightTypeList: [
      { title: 'Normal', maxNumberOfPilots: 2, minNumberOfPilots: 1, typeID: 3 },
      { title: 'Schulung Einzeln', maxNumberOfPilots: 1, minNumberOfPilots: 1, typeID: 1 },
      { title: 'Schulung Doppelsitzig', maxNumberOfPilots: 2, minNumberOfPilots: 2, typeID: 2 },
      { title: 'Überprüfung', maxNumberOfPilots: 2, minNumberOfPilots: 2, typeID: 8 },
      { title: 'Werkstattflug', maxNumberOfPilots: 2, minNumberOfPilots: 1, typeID: 5 },
      { title: 'Gutscheinflug', maxNumberOfPilots: 2, minNumberOfPilots: 2, typeID: 6 },
      { title: 'Gastflug', maxNumberOfPilots: 2, minNumberOfPilots: 2, typeID: 7 },
      { title: 'Einweisung', maxNumberOfPilots: 2, minNumberOfPilots: 1, typeID: 4 },
      { title: 'Kommt', maxNumberOfPilots: 2, minNumberOfPilots: 1, typeID: 9 },
      { title: 'Geht', maxNumberOfPilots: 2, minNumberOfPilots: 1, typeID: 10 }
    ],
    PlaneTypeList: [
      { title: 'Segler', typeID: 1 },
      { title: 'Motorsegler', typeID: 2 },
      { title: 'Motorflugzeug', typeID: 3 },
      { title: 'Segler mit Motor', typeID: 4 },
      { title: 'Ultraleicht', typeID: 5 },
      { title: 'Sonstige', typeID: 6 }
    ],
    PaymentTypeList: [
      { title: 'Standard', paymenttypeID: 1 },
      { title: 'Gutschein', paymenttypeID: 2 },
      { title: 'Gutschein, Rest Abbuchen', paymenttypeID: 3 },
      { title: 'Gutschein, Rest Bar', paymenttypeID: 4 },
      { title: 'Anderer/mehrere Zahler', paymenttypeID: 5 },
      { title: 'Verein zahlt', paymenttypeID: 6 },
      { title: 'Bar', paymenttypeID: 7 }
    ],
    MenbershipTypeList: [
      { title: 'Regulär', typeID: 1 },
      { title: 'Fördermitglied', typeID: 2 },
      { title: 'Ehrenmitglied', typeID: 3 },
      { title: 'Nichtmitglied', typeID: 4 },
      { title: 'Anderer Flugverein (Fliegerlager)', typeID: 5 }
    ],
    HttpStatusCodes: [
      { code: 100, status: 'Continue' },
      { code: 101, status: 'Switching Protocols' },
      { code: 102, status: 'Processing' },
      { code: 103, status: 'Early Hints' },
      { code: 200, status: 'OK' },
      { code: 201, status: 'Created' },
      { code: 202, status: 'Accepted' },
      { code: 203, status: 'Non-Authoritative Information' },
      { code: 204, status: 'No Content' },
      { code: 205, status: 'Reset Content' },
      { code: 206, status: 'Partial Content' },
      { code: 300, status: 'Multiple Choices' },
      { code: 301, status: 'Moved Permanently' },
      { code: 302, status: 'Found' },
      { code: 303, status: 'See Other' },
      { code: 304, status: 'Not Modified' },
      { code: 305, status: 'Use Proxy' },
      { code: 306, status: 'Unused' },
      { code: 307, status: 'Temporary Redirect' },
      { code: 308, status: 'Permanent Redirect' },
      { code: 400, status: 'Bad Request' },
      { code: 401, status: 'Unauthorized' },
      { code: 402, status: 'Payment Required' },
      { code: 403, status: 'Forbidden' },
      { code: 404, status: 'Not Found' },
      { code: 405, status: 'Method Not Allowed' },
      { code: 406, status: 'Not Acceptable' },
      { code: 407, status: 'Proxy Authentication Required' },
      { code: 408, status: 'Request Timeout' },
      { code: 409, status: 'Conflict' },
      { code: 410, status: 'Gone' },
      { code: 411, status: 'Length Required' },
      { code: 412, status: 'Precondition Failed' },
      { code: 413, status: 'Request Entity Too Large' },
      { code: 414, status: 'Request-URI Too Long' },
      { code: 415, status: 'Unsupported Media Type' },
      { code: 416, status: 'Requested Range Not Satisfiable' },
      { code: 417, status: 'Expectation Failed' },
      { code: 418, status: 'I`m a teapot' },
      { code: 421, status: 'Misdirected Request' },
      { code: 422, status: 'Unprocessable Entity' },
      { code: 423, status: 'Locked' },
      { code: 425, status: 'Too Early' },
      { code: 426, status: 'Upgrade Required' },
      { code: 428, status: 'Precondition Required' },
      { code: 429, status: 'Too Many Requests' },
      { code: 431, status: 'Request Header Fields Too Large' },
      { code: 451, status: 'Unavailable For Legal Reasons' },
      { code: 500, status: 'Internal Server Error' },
      { code: 501, status: 'Not Implemented' },
      { code: 502, status: 'Bad Gateway' },
      { code: 503, status: 'Service Unavailable' },
      { code: 504, status: 'Gateway Timeout' },
      { code: 505, status: 'HTTP Version Not Supported' },
      { code: 506, status: 'Variant Also Negotiates' },
      { code: 507, status: 'Insufficient Storage' },
      { code: 511, status: 'Network Authentication Required' },
      { code: 520, status: 'Web server is returning an unknown error' },
      { code: 522, status: 'Connection timed out' },
      { code: 524, status: 'A timeout occurred' }
    ],
    User: {
      userID: -1, // DEFAULT: -1
      auth: {
        username: '', /// DEFAULT: ''
        password: '', /// DEFAULT: ''
        authStr: '' /// DEFAULT: ''
      }
    }
  },
  mutations: {
    logout: (state) => {
      state.User.userID = -1
      state.User.auth.username = ''
      state.User.auth.password = ''
      state.User.auth.authStr = ''

      Vue.$cookies.remove('User')
    },
    setApiErrorResponse: (state, Response) => {
      state.ApiErrorResponse = Response
    },
    deleteApiErrorResponse: (state) => {
      state.ApiErrorResponse = []
    },
    setUser: (state, User) => {
      Vue.$cookies.set('User', JSON.stringify(User))

      state.User.userID = User.userID
      state.User.auth.username = User.auth.username
      state.User.auth.password = User.auth.password
      state.User.auth.authStr = User.auth.authStr
    },
    updateHeartbeat: (state, newData) => {
      state.Heartbeat = newData
      if (state.HeartbeatResponseStatistic.length >= 180) {
        state.HeartbeatResponseStatistic.shift()
        state.HeartbeatResponseStatistic.push(newData.response_time)
      } else {
        state.HeartbeatResponseStatistic.push(newData.response_time)
      }
    },
    saveAirportData: (state, airport) => {
      state.Airport = airport
    },
    saveMetarICAO: (state, icao) => {
      localStorage.setItem('MetarICAO', icao)
      state.MetarICAO = icao
    },
    saveDefaultLocation: (state, location) => {
      localStorage.setItem('defaultLocation', location)
      state.presets.defaultLocation = location
    },
    initialiseStore (state) {
      state.FlightLogDate = moment().format('YYYY-MM-DD')
      if (Vue.$cookies.isKey('User')) {
        state.User = Vue.$cookies.get('User')
      }
      if (localStorage.getItem('MetarICAO')) {
        state.MetarICAO = localStorage.getItem('MetarICAO')
      }
      if (localStorage.getItem('defaultLocation')) {
        state.presets.defaultLocation = localStorage.getItem('defaultLocation')
      }
      if (localStorage.getItem('roamingMode')) {
        state.RoamingMode = (localStorage.getItem('roamingMode') === 'true')
      }
    },
    savePilotList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
        newList[i].fullname = newList[i].firstname + ' ' + newList[i].lastname // Auto Generate Full-Name
      }
      state.PilotList = newList
    },
    savePaymentTypeList: (state, newList) => {
      state.PaymentTypeList = newList
    },
    savePlaneList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'instructors') continue
          if (key === 'lastMotorStop' && value != null) {
            newList[i].lastMotorStop = parseFloat(value)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.PlaneList = newList
    },
    saveBookings: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        newList[i].planeID = parseInt(newList[i].planeID)
        newList[i].userID = parseInt(newList[i].userID)
        newList[i].bookingID = parseInt(newList[i].bookingID)
        newList[i].canEdit = parseInt(newList[i].canEdit)
      }
      state.Bookings = newList
    },
    saveAirportList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AirportList = newList
    },
    saveAttendanceList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      state.AttendanceList = newList
    },
    saveFlightTypeList: (state, newList) => {
      state.FlightTypeList = newList
    },
    saveLaunchmethodList: (state, newList) => {
      state.LaunchmethodList = newList
    },
    saveAirportWeather: (state, newWeather) => {
      state.Airport = newWeather
    },
    saveAirportTimeInformation: (state, newTimeInfos) => {
      state.TimeInformations = newTimeInfos
    },
    addFlightToFlightList: (state, flight) => {
      //  console.log('Adding new Flight to Online Flight List')
      state.OnlineFlightList.push(flight)
    },
    saveFlightList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
      /*
      replace engine times
      */
        // if (newList[i].engineStart != null && newList[i].engineStart.indexOf('.') !== -1) {
        //   newList[i].engineStart = newList[i].engineStart.replace('.', ',')
        // }
        // if (newList[i].engineStop != null && newList[i].engineStop.indexOf('.') !== -1) {
        //   newList[i].engineStop = newList[i].engineStop.replace('.', ',')
        // }
        /*
        parse Integers
      */
        for (var [key, value] of Object.entries(newList[i])) {
          if (key === 'engineStop' || key === 'engineStart') {
            newList[i][key] = parseFloat(value)
            continue
          }
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      //  console.log(newList)
      state.OnlineFlightList = newList
    },
    saveStatisticsFlightList: (state, newList) => {
      for (var i = 0; i < newList.length; i++) {
        for (var [key, value] of Object.entries(newList[i])) {
          if (!isNaN(value) && value !== null) {
            newList[i][key] = parseInt(value)
          }
        }
      }
      //  console.log(newList)
      state.StatisticsFlightList = newList
    },
    setFlightLogDate: (state, newDate) => {
      state.FlightLogDate = newDate
    }
  },
  actions: {
    changeRoamingMode: ({ state, dispatch }) => {
      //  state.RoamingMode = !state.RoamingMode
      var mode = state.RoamingMode

      dispatch('stopTimers')

      if (mode) {
        //  activate mobile
        state.Intervals.FlightReloadInterval = 900000
        state.Intervals.HeartbeatInterval = 60000
      } else {
        //  activate network
        state.Intervals.FlightReloadInterval = 3000
        state.Intervals.HeartbeatInterval = 2500
      }
      dispatch('initHeartbeatTimer')
      dispatch('syncFlightTableAndReinitTimer')
      localStorage.setItem('roamingMode', state.RoamingMode)
    },
    async loginUser ({ state, commit, dispatch }, User) {
      commit('setUser', User)
      dispatch('setFlightLogDate', moment().format('YYYY-MM-DD'))
    },
    async loadPaymentTypeList ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_PaymentTypeList + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password)
          .then(response => {
            //  console.log(response)
            commit('savePaymentTypeList', response.data.results)
          })
      }
    },
    async loadLaunchtypeList ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_LAUNCHTYPELIST + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password)
          .then(response => {
            //  console.log(response)
            commit('saveLaunchtypeList', response.data.results)
          })
      }
    },
    async loadFlightTypeList ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_FLIGHTTYPELIST + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password)
          .then(response => {
            //  //  console.log(response)
            commit('saveFlightTypeList', response.data.results)
          })
      }
    },
    async loadPilotList ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        const response = await axios.get(process.env.VUE_APP_API_PATH_PILOTLIST + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
        commit('savePilotList', response.data.result)
      }
    },
    async loadPlaneList ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        const response = await axios.get(process.env.VUE_APP_API_PATH_PLANELIST + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
        commit('savePlaneList', response.data.result)
        return true
      }
    },
    async loadAirportList ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        const response = await axios.get(process.env.VUE_APP_AIRPORT_LIST + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
        commit('saveAirportList', response.data)
      }
    },
    async loadStatisticsFlightList ({ state, commit }) {
      commit('saveStatisticsFlightList', [])
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_STATISTICSFLIGHTLIST + '?d=' + this.state.FlightLogDate + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
            //  console.log(response)
            commit('saveStatisticsFlightList', response.data.result)
          })
      }
    },
    async loadFlightList ({ state, commit }) {
      //  console.log('Loading Flights from Database....')
      if (this.state.User.userID !== -1) {
        axios
          .get(process.env.VUE_APP_API_PATH_FLIGHTLIST + '?d=' + this.state.FlightLogDate + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
          .then(response => {
          //  //  console.log(response)
            commit('saveFlightList', response.data.result)
          })
      }
    },
    async syncFlightTableAndReinitTimer ({ state, dispatch }) {
      if (state.User.userID === -1) {
        return
      }
      await dispatch('stopFlightReloadTimers')
      dispatch('loadFlightList')
      dispatch('getAttendance')
      if (state.RoamingMode === false) {
        state.Intervals.FlightReload = setInterval(async function () {
          await dispatch('loadFlightList')
          await dispatch('getAttendance')
        }, state.Intervals.FlightReloadInterval)
      }
    },
    async initHeartbeatTimer ({ state, commit, dispatch }) {
      if (state.User.userID === -1) {
        return
      }
      dispatch('stopHeartbeatTimers')
      state.Intervals.Heartbeat = setInterval(() => {
        if (state.User.userID !== -1) {
          axios
            .get(process.env.VUE_APP_MAIN_API_HEARTBEAT_URL + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
            .then(response => {
              var newData = {
                response_status_code: response.status,
                response_status_text: response.statusText,
                response_time: new Date().getTime() - response.config.meta.requestStartedAt,
                last_request_time: new Date()
              }
              commit('updateHeartbeat', newData)
            }).catch(err => {
              if (err.response) {
                // client received an error response (5xx, 4xx)
                var newData = {
                  response_status_code: err.response.status,
                  response_status_text: '',
                  response_time: -1,
                  last_request_time: new Date()
                }
                commit('updateHeartbeat', newData)

                if (err.response.status === 401) {
                  dispatch('logoutBackend', state.User)
                }
              } else if (err.request) {
                // client never received a response, or request never left
                //  console.log('No Connection')
                var newData2 = {
                  response_status_code: 408,
                  response_status_text: '',
                  response_time: -1,
                  last_request_time: new Date()
                }
                commit('updateHeartbeat', newData2)
              } else {
                // anything else
              }
            })
        }
      }, state.Intervals.HeartbeatInterval)
    },
    async stopTimers ({ state, dispatch }) {
      dispatch('stopHeartbeatTimers')
      dispatch('stopFlightReloadTimers')
    },
    async stopFlightReloadTimers () {
      // Stop FlightReload Timer
      clearInterval(this.state.Intervals.FlightReload)
    },
    async stopHeartbeatTimers () {
      // Stop Heartbeat
      clearInterval(this.state.Intervals.Heartbeat)
    },
    async initialiseAction ({ state, dispatch }) {
      await dispatch('stopTimers')
      await dispatch('checkToken', state.User)
      await dispatch('loadPilotList')
      await dispatch('getAttendance')
      await dispatch('loadMetar')
      await dispatch('loadAirportList')
      await dispatch('loadPlaneList')
      await dispatch('initHeartbeatTimer')
      await dispatch('syncFlightTableAndReinitTimer')
      await dispatch('loadStatisticsFlightList')
      await dispatch('loadBookings')
    },
    afterLogoutActions () {
      window.localStorage.removeItem('recentFlightObject')
    },
    async updateMetar ({ dispatch }) {
      await dispatch('loadMetar')
    },
    async loadMetar ({ commit, state, dispatch }) {
      var airport = {}
      if (state.MetarICAO !== '' && state.MetarICAO.length === 4 && state.User.userID !== -1) {
        const response = await axios.get(process.env.VUE_APP_METAR_API_URL + state.MetarICAO.toUpperCase() + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
        if (response.data.noErrors === 0) {
          airport.Latitude = parseFloat(response.data.result.lat)
          airport.Longitude = parseFloat(response.data.result.lon)
          airport.Metar = response.data.result.METAR
          commit('saveAirportWeather', airport)
          await dispatch('loadAirportTimeInformation')
        } else {
          commit('setApiErrorResponse', response.data)
        }
      }
    },
    async loadAirportTimeInformation ({ commit, state, dispatch }) {
      if (state.Airport.Latitude != null && state.Airport.Longitude != null) {
        axios
          .get(process.env.VUE_APP_SUNRISE_SUNSET_API_URL + '?lat=' + state.Airport.Latitude + '&lng=' + state.Airport.Longitude + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password)
          .then(response => {
            //  console.log(response)
            commit('saveAirportTimeInformation', response.data.results)
          })
      } else {
        await dispatch('loadMetar')
      }
    },
    setFlightLogDate ({ dispatch, commit }, date) {
      commit('setFlightLogDate', date)
      dispatch('initialiseAction')
    },
    async validatePlane ({ state }, plane) {
      //  console.log(qs.stringify(plane))
      return axios.post(process.env.VUE_APP_API_PATH_VALIDATE_PLANE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(plane), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updatePlane ({ state, dispatch }, plane) {
      return axios.post(process.env.VUE_APP_API_PATH_EDIT_PLANE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(plane), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async insertPlane ({ state, dispatch }, plane) {
      return axios.post(process.env.VUE_APP_API_PATH_ADD_PLANE + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(plane), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async validateFlight ({ state, dispatch }, flight) {
      return axios.post(process.env.VUE_APP_API_PATH_VALIDATE_FLIGHT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(flight), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async insertFlight ({ state, dispatch }, flight) {
      return axios.post(process.env.VUE_APP_API_PATH_ADD_FLIGHT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(flight), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async parseIGCFile ({ state, dispatch }, data) {
      return axios.post(process.env.VUE_APP_API_IGC_PARSER + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify({ fileContent: data }), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteFlight ({ state, dispatch }, flight) {
      return axios.get(process.env.VUE_APP_API_PATH_DELETE_FLIGHT + '?flightID=' + flight.flightID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateFlight ({ state, dispatch }, flight) {
      return axios.post(process.env.VUE_APP_API_PATH_UPDATE_FLIGHT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(flight), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async validateUser ({ state, dispatch }, user) {
      return axios.post(process.env.VUE_APP_API_PATH_VALIDATE_PILOT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(user), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async insertUser ({ state, dispatch }, user) {
      return axios.post(process.env.VUE_APP_API_PATH_ADD_PILOT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(user), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async getFullUserInfo ({ state, dispatch }, id) {
      return axios.get(process.env.VUE_APP_API_PATH_GET_FULL_PILOT_INFO + '?userID=' + id + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateUser ({ state, dispatch }, user) {
      return axios.post(process.env.VUE_APP_API_PATH_EDIT_PILOT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(user), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async checkUserCanChangeUser ({ state, dispatch }) {
      if (this.state.User.userID !== -1) {
        const res = await axios.get(process.env.VUE_APP_API_CHECK_USER_PERMISSION_CHANGE_USERS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
        return res.data.result[0]
      }
    },
    async checkUserCanChangeBooking ({ state, dispatch }) {
      if (this.state.User.userID !== -1) {
        const res = await axios.get(process.env.VUE_APP_API_CHECK_USER_PERMISSION_CHANGE_BOOKING + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
        return res.data.result[0]
      }
    },
    async loadBookings ({ state, commit }) {
      if (this.state.User.userID !== -1) {
        const response = await axios.get(process.env.VUE_APP_API_PATH_GET_BOOKINGS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
        //  console.log(response)
        commit('saveBookings', response.data.result)
        return response.data.result
      }
    },
    async fileFlightReport ({ state, dispatch }, report) {
      console.log(report)
      return axios.post(process.env.VUE_APP_API_PATH_FLIGHTREPORT_ADD + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(report), { timeout: 10000, headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async insertBooking ({ state, dispatch }, booking) {
      return axios.post(process.env.VUE_APP_API_PATH_ADD_BOOKING + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(booking), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async updateBooking ({ state, dispatch }, booking) {
      return axios.post(process.env.VUE_APP_API_PATH_EDIT_BOOKING + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify(booking), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async deleteBooking ({ state, dispatch }, booking) {
      return axios.get(process.env.VUE_APP_API_PATH_DELETE_BOOKING + '?bookingID=' + booking.bookingID + '&user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    },
    async checkToken ({ state, dispatch, commit }, User) {
      if (User.userID !== -1) {
        const check = await axios.post(process.env.VUE_APP_API_PATH_VALIDATE_TOKEN + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, qs.stringify({ userName: User.auth.username, password: User.auth.password }))
        if (parseInt(check.data.result.userID) !== -1 && parseInt(check.data.result.userID) === User.userID) {
          console.log('Token Check Successful')
        } else {
          commit('setUser', {
            userID: -1, // DEFAULT: -1
            auth: {
              username: '', // DEFAULT: ''
              password: '', // DEFAULT: ''
              authStr: '' /// DEFAULT: ''
            }
          })
        }
      }
    },
    async logoutBackend ({ state, commit, dispatch }, User) {
      axios.post(process.env.VUE_APP_API_PATH_LOGOUT + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, [], { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
      dispatch('stopTimers')
      dispatch('afterLogoutActions')
      commit('logout')
    },
    async getAttendance ({ state, commit }) {
      return axios.get(process.env.VUE_APP_API_PATH_PRESENCE_LIST_GET + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password, { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } }).then(response => {
        commit('saveAttendanceList', response.data.result)
      })
    },
    async insertAttendance ({ state, dispatch }, status) {
      return axios.get(process.env.VUE_APP_API_PATH_PRESENCE_CHANGE_STATUS + '?user=' + state.User.auth.username + '&pw=' + state.User.auth.password + '&' + qs.stringify(status), { headers: { Authorization: `Basic ${this.state.User.auth.authStr}` } })
    }
  },
  modules: {
  }
})

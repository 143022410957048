<template>
  <v-data-table
    :headers="headers"
    :items="computedAttendanceList"
    no-data-text="Keine Mitglieder anwesend..."
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Anwesenheit</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
            <v-icon left>
        mdi-plus
      </v-icon>
              Einchecken
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Mitglied einchecken</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="9"
                    md="9"
                  >
                  <v-autocomplete
                            v-model="editedItem.userID"
                            :items="computedPilotList"
                            clearable
                            dense
                            outlined
                            item-value="userID"
                            item-text="fullname"
                            label="Pilot"
                          >
                          </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                  <v-btn
                color="success"
                @click="checkin"
              >
                Checkin
              </v-btn>
                  </v-col>
                              </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="close"
              >
                Abbrechen
                   </v-btn>
                                 <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-container>
        <v-row>
              <v-col sm="3" cols="12">
                <v-icon
        color="primary"
        class="mr-2"
      >
        fa-tractor
      </v-icon>
       = Windenstart
              </v-col>
        <v-col sm="3" cols="12">
          <v-icon
        color="primary"
        class="mr-2"
      >
      fa-trailer
      </v-icon>
      = F-Schlepp
        </v-col>
     <v-col sm="3" cols="12">
      <v-icon
        color="primary"
        class="mr-2"
      >
        fa-plane-departure
      </v-icon>
      = Eigenstart
     </v-col>
       <v-col sm="3" cols="12">
        <v-icon
        color="primary"
        class="mr-2"
      >
        fa-user-friends
      </v-icon>
      = Gastflug
       </v-col>
            </v-row>
      </v-container>
             <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        dense
        color="error"
        class="mr-2"
        @click="checkout(item)"
      >
        fa-sign-out-alt
      </v-icon>
    </template>
    <template v-slot:item.gliding="{ item }">
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        dense
        :color="getColor(item.gliderW)"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
        fa-tractor
      </v-icon>
      </template>
      <span>Windenstart</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        dense
        :color="getColor(item.gliderF)"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
      fa-trailer
      </v-icon>
      </template>
      <span>F-Schlepp</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        dense
        :color="getColor(item.gliderE)"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
        fa-plane-departure
      </v-icon>
      </template>
      <span>Eigenstart</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        dense
        :color="getColor(item.gliderG)"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
        fa-user-friends
      </v-icon>
      </template>
      <span>Gastflüge</span>
    </v-tooltip>
    </template>
    <template v-slot:item.mose="{ item }">
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        dense
        :color="getColor(item.tmgE)"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
        fa-plane-departure
      </v-icon>
      </template>
      <span>Eigenstart</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        dense
        :color="getColor(item.tmgF)"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
      fa-trailer
      </v-icon>
      </template>
      <span>F-Schlepp</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        dense
        :color="getColor(item.tmgG)"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
        fa-user-friends
      </v-icon>
      </template>
      <span>Gastflüge</span>
    </v-tooltip>
    </template>
    <template v-slot:item.ul="{ item }">
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        dense
        :color="getColor(item.ulE)"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
        fa-plane-departure
      </v-icon>
      </template>
      <span>Eigenstart</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        dense
        :color="getColor(item.ulF)"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
        fa-trailer
      </v-icon>
      </template>
      <span>F-Schlepp</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        dense
        :color="getColor(item.ulG)"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
        fa-user-friends
      </v-icon>
      </template>
      <span>Gastflüge</span>
    </v-tooltip>
    </template>
  </v-data-table>
</template>
<script>
import moment from 'moment'
export default {
  data: () => ({
    dialog: false,
    interval: null, // NEEDED FOR AUTO REFRESH
    search: '',
    headers: [
      { text: 'Vorname', value: 'firstname' },
      { text: 'Nachname', value: 'lastname' },
      { text: 'Rechte Segelflug', value: 'gliding' },
      { text: 'Rechte Motorflug', value: 'mose' },
      { text: 'Rechte Ultraleicht', value: 'ul' },
      { text: 'Als abwesend melden', value: 'actions' }
    ],
    editMenu: false,
    editedItem: {
      userID: '',
      status: null
    },
    defaultItem: {
      userID: '',
      status: null
    }
  }),

  computed: {
    computedAttendentIDs () {
      var list = []
      this.computedAttendanceList.forEach(attandant => {
        list.push(attandant.userID)
      })
      return list
    },
    computedAttendanceList () {
      return this.$store.state.AttendanceList
    },
    computedPilotList () {
      return this.$store.state.PilotList.filter(e => !this.computedAttendentIDs.includes(e.userID))
    }
  },
  mounted () {
    this.loadAttendance() // Load Attendance Once
    this.interval = setInterval(() => {
      this.loadAttendance() // Load Attendance every 10 Seconds
    }, 10000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  methods: {
    formatDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    getColor: function (bool) {
      if (parseInt(bool) === 1) {
        return 'green'
      }
      return 'red'
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    checkin () {
      this.editedItem.status = 1 // 1=Anwesend
      this.save()
    },
    checkout (item) {
      this.editedItem.userID = item.userID
      this.editedItem.status = 0 // 0=Anwesend
      this.save()
    },
    save () {
      this.$store.dispatch('insertAttendance', this.editedItem).then(response => {
        //  console.log(response)
        if (response.data.noErrors === 0) {
          // Add Attendace
          this.loadAttendance()
          this.close()
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    },
    loadAttendance () {
      this.$store.dispatch('getAttendance')
    }
  }
}
</script>

<template>
<v-container fluid>
  <PresenceList/>
</v-container>
</template>

<script>
import PresenceList from '../components/AttendanceList.vue'
export default {
  name: 'Presence',
  components: {
    PresenceList
  }
}
</script>

<template>
<v-container fluid>
<InformationCard></InformationCard>
<v-divider></v-divider>
  <FlightTable></FlightTable>
</v-container>
</template>

<script>
import FlightTable from '../components/FlightList.vue'
import InformationCard from '../components/InformationCard.vue'
export default {
  name: 'Flightlog',
  components: {
    FlightTable,
    InformationCard
  }
}
</script>

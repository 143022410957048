<template>
<v-container fluid>
  <BookingSchedule v-if="$store.state.User.userID != -1"></BookingSchedule>
</v-container>
</template>

<script>
import BookingSchedule from '../components/BookingScheduleNew.vue'
export default {
  name: 'Bookings',
  components: {
    BookingSchedule
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.computedAttendanceList,"no-data-text":"Keine Mitglieder anwesend...","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Anwesenheit")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"success","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Einchecken ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Mitglied einchecken")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"9","md":"9"}},[_c('v-autocomplete',{attrs:{"items":_vm.computedPilotList,"clearable":"","dense":"","outlined":"","item-value":"userID","item-text":"fullname","label":"Pilot"},model:{value:(_vm.editedItem.userID),callback:function ($$v) {_vm.$set(_vm.editedItem, "userID", $$v)},expression:"editedItem.userID"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.checkin}},[_vm._v(" Checkin ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Abbrechen ")]),_c('v-spacer')],1)],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"3","cols":"12"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" fa-tractor ")]),_vm._v(" = Windenstart ")],1),_c('v-col',{attrs:{"sm":"3","cols":"12"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" fa-trailer ")]),_vm._v(" = F-Schlepp ")],1),_c('v-col',{attrs:{"sm":"3","cols":"12"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" fa-plane-departure ")]),_vm._v(" = Eigenstart ")],1),_c('v-col',{attrs:{"sm":"3","cols":"12"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" fa-user-friends ")]),_vm._v(" = Gastflug ")],1)],1)],1),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"dense":"","color":"error"},on:{"click":function($event){return _vm.checkout(item)}}},[_vm._v(" fa-sign-out-alt ")])]}},{key:"item.gliding",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":_vm.getColor(item.gliderW)}},'v-icon',attrs,false),on),[_vm._v(" fa-tractor ")])]}}],null,true)},[_c('span',[_vm._v("Windenstart")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":_vm.getColor(item.gliderF)}},'v-icon',attrs,false),on),[_vm._v(" fa-trailer ")])]}}],null,true)},[_c('span',[_vm._v("F-Schlepp")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":_vm.getColor(item.gliderE)}},'v-icon',attrs,false),on),[_vm._v(" fa-plane-departure ")])]}}],null,true)},[_c('span',[_vm._v("Eigenstart")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":_vm.getColor(item.gliderG)}},'v-icon',attrs,false),on),[_vm._v(" fa-user-friends ")])]}}],null,true)},[_c('span',[_vm._v("Gastflüge")])])]}},{key:"item.mose",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":_vm.getColor(item.tmgE)}},'v-icon',attrs,false),on),[_vm._v(" fa-plane-departure ")])]}}],null,true)},[_c('span',[_vm._v("Eigenstart")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":_vm.getColor(item.tmgF)}},'v-icon',attrs,false),on),[_vm._v(" fa-trailer ")])]}}],null,true)},[_c('span',[_vm._v("F-Schlepp")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":_vm.getColor(item.tmgG)}},'v-icon',attrs,false),on),[_vm._v(" fa-user-friends ")])]}}],null,true)},[_c('span',[_vm._v("Gastflüge")])])]}},{key:"item.ul",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":_vm.getColor(item.ulE)}},'v-icon',attrs,false),on),[_vm._v(" fa-plane-departure ")])]}}],null,true)},[_c('span',[_vm._v("Eigenstart")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":_vm.getColor(item.ulF)}},'v-icon',attrs,false),on),[_vm._v(" fa-trailer ")])]}}],null,true)},[_c('span',[_vm._v("F-Schlepp")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","color":_vm.getColor(item.ulG)}},'v-icon',attrs,false),on),[_vm._v(" fa-user-friends ")])]}}],null,true)},[_c('span',[_vm._v("Gastflüge")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
<v-container dark>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="4">
            <v-text-field cols="4" v-model="metarICAO" label="METAR - ICAO"></v-text-field>
        </v-col>
            <v-col cols="12" sm="4">
                      <v-autocomplete
                            v-model="defaultLocation"
                            :items="computedAirportList"
                            cols="4"
                            item-value="icao"
                            label="Standart ICAO für Start und Landung"
                          >
                            <template slot="selection" slot-scope="data">
                              <!-- HTML that describe how select should render selected items -->
                              {{ data.item.icao }} {{ data.item.city }}
                            </template>
                            <template slot="item" slot-scope="data">
                              <!-- HTML that describe how select should render items when the select is open -->
                              {{ data.item.icao }} {{ data.item.city }}
                            </template>
                          </v-autocomplete>
        </v-col>
    </v-row>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="4" dark>
            <span class="display-1">~Florian
            <p class="subtitle-1">Backend API development | Database construction</p></span>
            <span class="display-1">~Jason
            <p class="subtitle-1">Frontend Application development | Operations</p>
            </span>
            <v-divider></v-divider>
            <span class="display-1">Weather Provider
            <p class="subtitle-1">https://www.windy.com</p></span>
            <span class="display-1">METAR Provider
            <p class="subtitle-1">https://aviationweather.gov</p></span>
            <span class="display-1">Sun - Times Provider
            <p class="subtitle-1">https://api.sunrise-sunset.org</p></span>
            <span class="display-1">GlideStone-Version
            <p class="subtitle-1">{{ GlideStoneVersion || 'DEVELOPMENT' }}</p></span>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
export default {
  name: 'about',
  data: () => ({
    metarICAO: '',
    defaultLocation: ''
  }),
  watch: {
    metarICAO: function (newICAO, oldICAO) {
      this.$store.commit('saveMetarICAO', newICAO)
    },
    defaultLocation: function (newLcoation, oldLocvation) {
      this.$store.commit('saveDefaultLocation', newLcoation)
    }
  },
  computed: {
    GlideStoneVersion () {
      return process.env.VUE_APP_VERSION
    },
    computedAirportList () {
      var airports = []
      var AirportList = this.$store.state.AirportList

      for (var i = 0; i < AirportList.length; i++) {
        airports.push({
          icao: AirportList[i].ICAO,
          city: AirportList[i].cityName
        })
      }
      return airports
    }
  },
  mounted: function () {
    this.metarICAO = this.$store.state.MetarICAO
    this.defaultLocation = this.$store.state.presets.defaultLocation
  }
}
</script>

<template>
  <v-row>
    <v-col>
      <v-sheet :height="screenHeight">
        <v-calendar
          ref="calendar"
          v-model="value"
          type="4day"
          locale="de"
          :events="events"
          event-overlap-mode="column"
        >
        <template v-slot:event="{ event }">
            <div style="margin-left: 8px; margin-right: 5px;">
              <strong>{{event.callsign}}</strong>
            <p style="margin-bottom: 0px;"><strong>{{event.name}}</strong></p>
            <p v-if="event.type === 1"><u>GROUNDING</u></p>
            <p>
              {{event.comment}}
            </p>
            </div>
          </template>
          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
    <div class="text-center">
      <v-overlay :value="computedOverlay" color="black" :opacity="0.9">
      <v-progress-circular
      :size="300"
      :width="6"
      :value="100"
      color="primary"
      indeterminate
    > <span class="white--text text-h3"> <strong>BEREIT</strong></span></v-progress-circular>
    <p></p>
    <strong class="text-h4">BITTE NFC-TAG SCANNEN</strong>
    </v-overlay>
    </div>
  </v-row>
</template>
<style>
  .v-progress-circular--indeterminate svg {
    -webkit-animation: progress-circular-rotate 15s linear infinite !important;
    animation: progress-circular-rotate 15s linear infinite !important;
  }
  .v-progress-circular--indeterminate .v-progress-circular__overlay {
    -webkit-animation: progress-circular-dash 15s ease-in-out infinite !important;
    animation: progress-circular-dash 15s ease-in-out infinite !important;
  }
</style>
<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
<script>
import moment from 'moment'
export default {
  name: 'BookingCalendarOnly',
  data: () => ({
    value: '',
    ready: false,
    overlay: false,
    events: []
  }),
  computed: {
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    screenHeight () {
      return screen.height
    },
    scannerReady () {
      return 'ready' in this.$route.query
    },
    computedOverlay () {
      if (this.scannerReady) {
        return this.overlay
      }
      return false
    }
  },
  mounted () {
    this.ready = true
    this.scrollToTime()
    this.updateTime()
    this.loadEvents()
  },
  created () {
    setInterval(() => {
      this.loadEvents()
    }, 600000)
    setInterval(() => {
      this.overlay = !this.overlay
    }, 30000)
  },
  methods: {
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
    loadEvents: async function () {
      await this.$store.dispatch('loadBookings')
      await this.$store.dispatch('loadPilotList')
      await this.$store.dispatch('loadPlaneList')
      await this.$store.dispatch('stopTimers')
      if (this.initialLoad) {
        this.calendarPlaneFilter = this.planesForBooking[0].planeID
        this.scrollToTime()
        this.initialLoad = false
      }
      var events = []
      this.$store.state.Bookings.forEach(item => {
        item.name = this.$store.state.PilotList.filter(e => e.userID === item.userID)[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === item.userID)[0].lastname
        item.callsign = this.$store.state.PlaneList.filter(e => e.planeID === item.planeID)[0].callsign
        item.category = this.$store.state.PlaneList.filter(e => e.planeID === item.planeID)[0].callsign
        item.start = moment(item.start).valueOf()
        item.end = moment(item.end).valueOf()
        item.timed = true // Need to be set to verify that its not all the day long

        events.push(item)
      })
      this.events = events
    }
  }
}
</script>

<template>
  <v-app>
    <Navigation v-if="currentRouteName != 'calendarOnly'"></Navigation>

    <TopBar v-if="currentRouteName != 'calendarOnly'"></TopBar>

    <v-main :style="currentRouteName != 'calendarOnly' ? ($vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 35px') : ''" :class="$route.name == 'about' ? 'about' : ''">
      <router-view></router-view>
    </v-main>
    <LoginDialog></LoginDialog>

    <ErrorDialog></ErrorDialog>
  </v-app>
</template>
<script>
import axios from 'axios'
import Navigation from './components/Navigation.vue'
import TopBar from './components/TopBar.vue'
import LoginDialog from './components/LoginDialog.vue'
import ErrorDialog from './components/ErrorDialog.vue'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  onIdle () {
    if (this.$store.state.User.userID !== -1) {
      this.$store.dispatch('logoutBackend')
    }
  },
  components: {
    TopBar,
    Navigation,
    LoginDialog,
    ErrorDialog
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
  },
  created () {
    if (this.$store.state.MetarICAO === '' || this.$store.state.presets.defaultLocation === '') {
      this.$store.commit('setApiErrorResponse', { noErrors: 2, errorMsg: 'METAR ICAO musst be set! \n Default Airport Location must be set!' })
      this.$router.push({ name: 'about' })
    }
    axios.interceptors.request.use(x => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
      x.meta = x.meta || {}
      x.meta.requestStartedAt = new Date().getTime()
      return x
    })
    axios.interceptors.response.use(x => {
      x.responseTime = new Date().getTime() - x.config.meta.beginTimer
      return x
    }
    )
  }
}
</script>

<template>
  <v-app-bar
    clipped-left
    app
    dense
    style="background: rgb(2,0,36);
background: linear-gradient(45deg, rgba(2,0,36,1) 94%, rgba(16,204,111,1) 94%);"
  >
  <span class="white--text hidden-lg-and-up">
      <v-app-bar-nav-icon style="color: white;" @click="$root.$emit('toggleNavBarButton')"></v-app-bar-nav-icon>
    </span>
    <span class="white--text hidden-xs-only"><h2>GlideStone</h2></span>
    <span class="white--text hidden-sm-and-down" style="transform: translateY(-10px);">{{ GlideStoneVersion || 'DEVELOPMENT' }}</span>
    <v-spacer></v-spacer>
    <div v-if="flightLogDate != currentDate">
    <strong style="color: red">Anzeigedatum:
            {{
              formatDate(flightLogDate)
            }}</strong>
    </div>
    <div v-else>
    <h4 class="white--text hidden-md-and-down">
      {{ $store.state.User.userID != -1 ? 'Angemeldet als: ' + $store.state.User.auth.username : 'Bitte Anmelden!' }}
    </h4>
        <h4 class="white--text hidden-lg-and-up">
      {{ $store.state.User.userID != -1 ? $store.state.User.auth.username : 'Anmelden!' }}
    </h4>
    </div>
    <v-spacer></v-spacer>
    <v-menu content-class="custom" v-model="menu"  :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="white" dark v-bind="attrs" v-on="on" icon>
          <v-icon dark>
            mdi-dots-vertical
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <v-switch
              v-model="$vuetify.theme.dark"
              @change="changeDarkMode"
              :label="`${ $vuetify.theme.dark ? 'Dark mode': 'Light mode'}`"
            ></v-switch>
          </v-list-item-title>
        </v-list-item>
         <v-list-item>
          <v-list-item-title>
            <v-switch
              :label="`${ $store.state.RoamingMode ? 'Mobile mode': 'Network mode'}`"
              v-model="$store.state.RoamingMode"
              @change="changeRoamingMode"
            ></v-switch>
          </v-list-item-title>
        </v-list-item>
                <v-list-item>
          <v-list-item-title>
            <v-switch
              v-model="$store.state.FliegerlagerMode"
              label="Fliegerlager-Modus"
            ></v-switch>
          </v-list-item-title>
        </v-list-item>
                        <v-list-item>
          <v-list-item-title>
          <v-text-field
            v-model="date"
            label="Anzeigedatum"
            local="de-de"
            type="date"
          ></v-text-field>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="setNewDate" dark style="background-color: #10cc6f">
      <v-list-item-icon>
            <v-icon>fa-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Datum anzeigen
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="startDownSync" dark style="background-color: #ffb444">
      <v-list-item-icon>
            <v-icon>fa-cloud-download-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            DownSync
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="logoutCurrentUser" dark style="background-color: #F44">
        <v-list-item-icon>
            <v-icon>fa-sign-out-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Logout
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!--
      <v-spacer></v-spacer>
          <v-icon
          dark
          >
          mdi-dots-vertical
        </v-icon>
        -->
  </v-app-bar>
</template>
<style>
.custom {
    transform: translateY(35px) !important;
}
</style>
<script>
import moment from 'moment'

export default {
  name: 'TopBar',
  data: () => ({
    date: null,
    menu: false
  }),
  methods: {
    changeDarkMode: function () {
      console.log('Darkmode Change')
      this.$store.dispatch('changeDarkMode')
    },
    logoutCurrentUser: function () {
      this.$store.dispatch('logoutBackend')
    },
    startDownSync: function () {
      this.$store.dispatch('initialiseAction')
    },
    formatDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    changeRoamingMode: function () {
      console.log('Roaming Change')
      this.$store.dispatch('changeRoamingMode')
    },
    setNewDate: function () {
      if (this.date === '') this.date = this.currentDate
      this.$store.dispatch('setFlightLogDate', this.date)
      this.menu = false
      this.date = this.$store.state.FlightLogDate
    }
  },
  watch: {
  },
  computed: {
    GlideStoneVersion () {
      return process.env.VUE_APP_VERSION
    },
    currentDate () {
      return moment().format('YYYY-MM-DD')
    },
    flightLogDate () {
      return this.$store.state.FlightLogDate
    }
  },
  mounted () {
    this.date = this.$store.state.FlightLogDate
  }
}
</script>

<template>
<v-alert outlined :color="computedCardColor" style="padding: 5px; margin-bottom: 0px">
            <v-container>
              <v-row>
                <v-sparkline
                  :line-width="1"
                  :smooth="10 || false"
                  :padding="0"
                  :value="computedResponseTimeStatistic"
                  style="padding: 0px"
                  auto-draw
                ></v-sparkline
              ></v-row>
              <v-row style="padding: 5px" v-if="computedLastHeartbeat.response_status_code">
              <v-icon tile :color="computedCardColor">mdi-access-point</v-icon>  {{ computedLastHeartbeat.response_status_code }} / {{ $store.state.HttpStatusCodes.filter(e => e.code === computedLastHeartbeat.response_status_code)[0].status }} <v-spacer></v-spacer>
              <span>&#8709;{{ computedResponseTimeAverage }} ms</span>
              <v-spacer></v-spacer>
              {{ formatDateTime(computedLastHeartbeat.last_request_time) }}
              </v-row>
            </v-container>
        </v-alert>
</template>
<script>
import moment from 'moment'

export default {
  name: 'HeartBeatCard',
  data: () => ({
  }),
  computed: {
    computedResponseTimeStatistic () {
      return this.$store.state.HeartbeatResponseStatistic
    },
    computedResponseTimeAverage () {
      return Math.round(
        this.$store.state.HeartbeatResponseStatistic.slice(-20).reduce(function (
          a,
          b
        ) {
          return a + b
        },
        0) / 20
      )
    },
    computedLastHeartbeat () {
      return this.$store.state.Heartbeat
    },
    computedCardColor () {
      if (this.computedLastHeartbeat.response_time !== -1) {
        if (this.computedResponseTimeAverage < 300) {
          return 'green'
        } else if (this.computedResponseTimeAverage < 500) {
          return 'orange'
        } else {
          return 'red'
        }
      } else {
        return 'red'
      }
    }
  },
  methods: {
    formatDateTime (timestamp) {
      return moment(timestamp).format('HH:mm:ss')
    },
    formatTimestamp: function (unixTimestamp) {
      return moment(unixTimestamp).format('DD.MM.YYYY HH:mm:ss')
    },
    convert12HrTimeTo24Hr (time) {
      return moment(time, 'hh:mm:ss A').format('HH:mm')
    }
  }
}
</script>

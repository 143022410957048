import Vue from 'vue'
import VueRouter from 'vue-router'
import FlightLog from '../views/FlightLog.vue'
import AttendanceView from '../views/AttendanceList.vue'
import PilotList from '../views/PilotList.vue'
import PlaneList from '../views/PlaneList.vue'
import FlightStatistics from '../views/FlightStatistics.vue'
import About from '../views/About.vue'
import Weather from '../views/Weather.vue'
import Booking from '../views/Booking.vue'
import Coupon from '../views/Coupon.vue'
import BookingCalendarOnly from '../views/BookingCalendarOnly.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: { name: 'flightlog' } },
  {
    path: '/flights',
    name: 'flightlog',
    component: FlightLog
  },
  {
    path: '/anwesenheit',
    name: 'attendance',
    component: AttendanceView
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: FlightStatistics
  },
  {
    path: '/pilots',
    name: 'pilotlist',
    component: PilotList
  },
  {
    path: '/planes',
    name: 'planelist',
    component: PlaneList
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/weather',
    name: 'weather',
    component: Weather
  },
  {
    path: '/booking',
    name: 'booking',
    component: Booking
  },
  {
    path: '/coupon', // NEEDED IN CASE NO CODE IS PROVIDED
    name: 'coupon',
    component: Coupon
  },
  {
    path: '/coupon/:code',
    name: 'couponCode',
    component: Coupon
  },
  {
    path: '/bookingCalendar', // NEEDED IN CASE NO CODE IS PROVIDED
    name: 'calendarOnly',
    component: BookingCalendarOnly
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
